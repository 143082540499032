export enum WorkingStatus {
	WorkinginPractice = 605340000,
	WorkinginBusiness,
	WorkinginPublicSectorNotforProfit,
	MaternityPaternityLeave,
	Retired,
	Unemployed,
	CareerBreak,
	Unknown,
	UnpaidVoluntaryWorkSupport,
}
