import React, { FC } from 'react';
import { LiveRegion } from 'icas.core.reactcomponents';

import { SaveInfo } from '../../hooks/useHandleFormLogic';
import { customErrorInfo } from '../../types/CustomErrorInfo';

import { useFeedbackInterval } from './useFeedbackInterval';

import './FeedbackBarStyle.css';

type FeedbackBarProps = {
	invalidSubmit: boolean;
	saveInfo: SaveInfo;
	errorInfo?: customErrorInfo;
};

const InvalidMessage = () => (
	<div className="feedback__outer">
		<div className="feedback-container">
			<div className="feedback feedback-invalid">
				{' '}
				Please check your details and try again. You&apos;ll find more info
				highlighted on the form
			</div>
		</div>
	</div>
);

const SuccessMessage = () => (
	<div className="feedback__outer">
		<div className="feedback-container">
			<div className="feedback feedback-valid"> Changes saved</div>
		</div>
	</div>
);

const FailureMessage = ({ errorMessage }: { errorMessage?: string }) => {
	const message = errorMessage
		? `${errorMessage}. Please try again or contact ICAS`
		: `An error has occured. Please try again or contact ICAS`;
	return (
		<div className="feedback__outer">
			<div className="feedback-container">
				<div className="feedback feedback-invalid">{message}</div>
			</div>
		</div>
	);
};

const LoadingMessage = () => (
	<div className="feedback__outer">
		<div className="feedback-container">
			<div className="feedback feedback-saving">Saving changes...</div>
		</div>
	</div>
);

export const FeedbackBar: FC<FeedbackBarProps> = ({
	invalidSubmit,
	saveInfo,
	errorInfo,
}) => {
	const { feedbackType } = useFeedbackInterval(invalidSubmit, saveInfo);

	let screen = <></>;
	if (feedbackType === 'Loading') {
		screen = <LoadingMessage />;
	} else if (feedbackType === 'Invalid') {
		screen = <InvalidMessage />;
	} else if (feedbackType === 'Success') {
		screen = <SuccessMessage />;
	} else if (feedbackType === 'Failure') {
		screen = <FailureMessage errorMessage={errorInfo?.errorMessage} />;
	}

	return (
		<LiveRegion role="alert" atomic id={'status-message'}>
			{screen}
		</LiveRegion>
	);
};
