import React, { FC } from 'react';
import { Button, SubmitButton } from 'icas.core.reactcomponents';

import './SaveBarStyle.css';

type ActionType = 'Submit' | 'Reset';

type SaveBarProps = {
	handleFormAction: (actionType: ActionType) => void;
};

export const SaveBar: FC<SaveBarProps> = ({ handleFormAction }) => (
	<>
		<div className="save-bar__outer">
			<div className="save-bar">
				<Button
					id="reset"
					text="Reset Form"
					variant="ghost"
					onClick={() => handleFormAction('Reset')}
				/>
				<SubmitButton
					id="save"
					text="Save Changes"
					onClick={() => handleFormAction('Submit')}
				/>
			</div>
		</div>
	</>
);
