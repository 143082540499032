import React, { FC, useContext } from 'react';
import { LogRegion, Select, Stack, TextInput } from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import { WorkingStatus } from '../../enums/WorkingStatus';
import { AddressContainer } from '../../pages/Address/AddressContainer';
import { Employer } from '../../pages/Employer';
import {
	HandleFieldValidationFunction,
	HandleNestedOrgChangeFunction,
	HandleNewEmployerFunction,
	HandleTextChangeFunction,
	HandleWorkAddressChangeFunction,
} from '../../types/ChangeFunctions';
import { MyWorkData } from '../../types/MyWorkData';

import './CardStyle.css';

type WorkCardProps = {
	customer: MyWorkData;
	handleTextChange: HandleTextChangeFunction;
	handleNestedOrgChange: HandleNestedOrgChangeFunction;
	handleAddressChange: HandleWorkAddressChangeFunction;
	handleEmployerChange: HandleNewEmployerFunction;
	invalidSubmit: boolean;
	handleFieldValidation: HandleFieldValidationFunction;
};

export const WorkCard: FC<WorkCardProps> = ({
	customer,
	handleTextChange,
	handleAddressChange,
	handleNestedOrgChange,
	handleFieldValidation,
	handleEmployerChange,
	invalidSubmit = false,
}): JSX.Element => {
	const { icas_employeecountcode, icas_personanumberofpartners } =
		useContext(AppContext);
	const urlToSearch: string = !customer.organisation?.parentCompanyId
		? `/lookup/address/?addressString=`
		: `/firms/firms/${customer.organisation.parentCompanyId}/?addressQuery=`;

	//only show partners question if working in practise
	const inPractice: boolean =
		customer.workingStatus === WorkingStatus.WorkinginPractice;

	return (
		<Stack gap={3} as="section" className="card">
			<div className="card__heading orange">
				<h2>Where I work</h2>
			</div>
			<Stack
				as="div"
				direction="row"
				wrap="wrap"
				gap={3}
				className="card__content work-card"
			>
				<Stack as="div" gap={3} className="work-card__inner">
					<Employer
						currentEmployer={customer.organisation?.name}
						labelText={'Employer'}
						urlToSearch="/firms/firms/?nameQuery="
						handleEmployerChange={handleEmployerChange}
						required
						invalidSubmit={invalidSubmit}
						onFieldValidation={handleFieldValidation}
					/>

					{customer.organisation.name && (
						<AddressContainer
							address={customer.organisation?.address}
							labelText="Office address"
							handleAddressChange={handleAddressChange}
							urlToSearch={urlToSearch}
							onFieldValidation={handleFieldValidation}
							invalidSubmit={invalidSubmit}
							required
						/>
					)}
				</Stack>
				<Stack as="div" gap={3} className="work-card__inner">
					<TextInput
						id="workPhone"
						value={customer.workPhone?.toString()}
						labelText="Direct Number"
						handleChange={handleTextChange}
						size={30}
						vertical
						type="tel"
					/>
					<Select
						id={'companyEmployeeCount'}
						labelText="How many people are employed at your company (at all locations)?"
						handleChange={handleNestedOrgChange}
						options={icas_employeecountcode}
						selectedOption={customer.organisation.companyEmployeeCount}
						vertical
					/>
					<LogRegion id="in-practice">
						{inPractice && (
							<Select
								id={'personaPartnerCount'}
								labelText="How many partners are there at your firm?"
								handleChange={handleNestedOrgChange}
								options={icas_personanumberofpartners}
								selectedOption={customer.organisation.personaPartnerCount}
								vertical
							/>
						)}
					</LogRegion>
				</Stack>
			</Stack>
		</Stack>
	);
};
