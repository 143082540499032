import React, { FC, useContext } from 'react';
import { MultiSelect, Radio, Toggle } from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import { PostalPreference } from '../../enums/PostalPrefernce';
import { OptionSet } from '../../types/OptionSet';
import { PreferencesData } from '../../types/PreferencesData';

import './CardStyle.css';

type CommunityCardProps = {
	handleChange: (id: string, checked: boolean) => void;
	handleMultiSelectChange: (
		id: string,
		checked: boolean,
		multiSelectOptions: OptionSet[]
	) => void;
	data: PreferencesData;
	handleRadioChange: (name: string, value: string | number) => void;
	doNotBulkEmail: boolean;
};

export const CommunityCard: FC<CommunityCardProps> = ({
	handleChange,
	handleMultiSelectChange,
	handleRadioChange,
	data,
	doNotBulkEmail,
}): JSX.Element => {
	const { icas_memberinterestscode } = useContext(AppContext);
	const { showBusinessAddress, interests, mailPreferences } = data;

	const toggleInfo = {
		id: 'showBusinessAddress',
		labelText: 'Show my business address',
		isChecked: showBusinessAddress,
		description:
			"Make it easier to search for your Find a CA and CA Connect listings by displaying your business address. If you're not working, the city of your home address is shown.",
	};

	const onMultiSelectChange = (id: string, checked: boolean) => {
		handleMultiSelectChange(id, checked, icas_memberinterestscode);
	};

	const multiSelectDescription =
		"Get content that is most relevant to you by simply updating your profile to make sure our communications align with your interests, and you'll receive the news and insights that matter to you most.";

	if (icas_memberinterestscode && interests) {
		const checkedOptions = interests.map(interest => interest.Key.toString());
		return (
			<section className="card">
				<div className="card__heading orange">
					<h2>Community</h2>
				</div>
				<div className="card__content community-card">
					<Radio
						heading="Postal address preferences"
						name="mailPreferences"
						handleChange={handleRadioChange}
						selectedValue={mailPreferences ?? undefined}
					>
						<Radio.Description description="Let us know which address we should forward postal communications to:" />
						<Radio.ButtonContainer id="postal-radio__container">
							<Radio.Button
								id="home-option"
								labelText="Home"
								value={PostalPreference.Home}
							/>
							<Radio.Button
								id="work-option"
								labelText="Work"
								value={PostalPreference.Work}
							/>
						</Radio.ButtonContainer>
					</Radio>
					<hr />
					<Toggle
						onChange={handleChange}
						isChecked={toggleInfo.isChecked}
						labelText="Show your address in our directories"
						id={toggleInfo.id}
					>
						<Toggle.Description description={toggleInfo.description} />
						<Toggle.Switch labelText={toggleInfo.labelText} />
					</Toggle>
					<hr />
					<MultiSelect
						handleChange={onMultiSelectChange}
						checkedOptions={checkedOptions}
						heading="Your professional interests"
					>
						<MultiSelect.Description description={multiSelectDescription} />
						<MultiSelect.OptionContainer columnMax={2}>
							{icas_memberinterestscode.map(interest => (
								<MultiSelect.Option
									key={interest.value}
									id={interest.value.toString()}
									displayText={interest.name}
									disabled={doNotBulkEmail}
								/>
							))}
						</MultiSelect.OptionContainer>
					</MultiSelect>
				</div>
			</section>
		);
	}
	return <></>;
};
