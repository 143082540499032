import React from 'react';

import { AddressProps } from './AddressContainer';

import './AddressStyle.css';

export const AddressDisplay = ({ address }: AddressProps) => {
	if (address) {
		return (
			<div data-testid="address-read-only" className="icas-address__read-only">
				<span>{address.line1}</span>
				{address.line2 && <span>{address.line2}</span>}
				{address.line3 && <span>{address.line3}</span>}
				{address.city && <span>{address.city}</span>}
				{address.countryName && <span>{address.countryName}</span>}
				{address.postCode && <span>{address.postCode}</span>}
			</div>
		);
	}
	return null;
};
