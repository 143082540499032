import { useEffect, useState } from 'react';
import { OptionSet } from 'icas.core.reactcomponents';

import { arrayHasElements } from '../utils/validation';

const titleMissingInOptions = (titles: OptionSet[], title: string): boolean => {
	const existingTitle: [] | OptionSet[] = titles.filter(
		t => t.value === parseInt(title) || t.name === title
	);
	return !arrayHasElements(existingTitle);
};

export const useHandleCustomTitle = (
	titles: OptionSet[],
	title: string,
	handleChange: (id: string, value: string) => void
): {
	titles: OptionSet[];
	value: string | number;
	handleTitleChange: (id: string, value: string | number) => void;
} => {
	const [selectValue, setSelectValue] = useState<number | string>('');
	const [selectTitles, setSelectTitles] = useState<OptionSet[]>([]);
	const [customValueAdded, setCustomValueAdded] = useState(false);
	useEffect(() => {
		if (!customValueAdded) {
			setSelectTitles(titles);
		}
	}, [customValueAdded, titles]);

	useEffect(() => {
		const addMissingItem = () => {
			setSelectTitles(prevState => [...prevState, { name: title, value: -1 }]);
			setCustomValueAdded(true);
		};

		const setValue = () => {
			const option = selectTitles.find(
				t => t.name === title || t.value === parseInt(title)
			);
			setSelectValue(option?.value ?? '');
		};

		if (!customValueAdded) {
			if (title && title.length) {
				const missingOption: boolean = titleMissingInOptions(titles, title);
				if (missingOption) {
					addMissingItem();
				}
			}
		}
		setValue();
	}, [customValueAdded, selectTitles, title, titles]);

	const handleTitleChange = (id: string, value: string | number) => {
		const option = selectTitles.find(
			t => t.value === parseInt(value.toString())
		);
		if (!option) {
			return;
		}
		handleChange(id, option?.name);
	};

	return {
		titles: selectTitles,
		value: selectValue,
		handleTitleChange: handleTitleChange,
	};
};
