export const HouseholdOccupationAtAge14 = {
	ModernProfessionalAndTraditionalProfessionalOccupations: 605340000,
	SeniorOrMiddleOrJuniorManagersOrAdministrators: 605340001,
	ClericalAndIntermediateOccupations: 605340002,
	TechnicalAndCraftOccupations: 605340003,
	RoutineSemiRoutineManualAndServiceOccupations: 605340004,
	LongTermUnemployed: 605340005,
	SmallBusinessOwners: 605340006,
	Other: 605340007,
	IPreferNotToSay: 605340008,
};

export const HouseholdDescriptions = {
	[HouseholdOccupationAtAge14.ModernProfessionalAndTraditionalProfessionalOccupations]:
		'such as: teacher, nurse, physiotherapist, social worker, musician, police officer (sergeant or above), software designer, accountant, solicitor, medical practitioner, scientist, civil / mechanical engineer.',
	[HouseholdOccupationAtAge14.SeniorOrMiddleOrJuniorManagersOrAdministrators]:
		'such as: finance manager, large business owner, office manager, retail manager, bank manager, warehouse manager.',
	[HouseholdOccupationAtAge14.ClericalAndIntermediateOccupations]:
		'such as: secretary, personal assistant, call centre agent, clerical worker, nursery nurse.',
	[HouseholdOccupationAtAge14.TechnicalAndCraftOccupations]:
		'such as: mechanic, plumber, printer, electrician, train driver.',
	[HouseholdOccupationAtAge14.RoutineSemiRoutineManualAndServiceOccupations]:
		'such as: postal worker, machine operative, security guard, caretaker, farm worker, catering assistant, sales assistant, driver, cleaner, porter, packer, labourer, waiter/waitress, bar staff.',
	[HouseholdOccupationAtAge14.LongTermUnemployed]:
		'(claimed Jobseeker’s Allowance or earlier unemployment benefit for more than a year).',
	[HouseholdOccupationAtAge14.SmallBusinessOwners]:
		'who employed less than 25 people such as: shop owners, small plumbing companies, single restaurant or cafe owner, taxi owner, garage owner.',
	[HouseholdOccupationAtAge14.Other]:
		'such as: retired, this question does not apply to me, I don’t know.',
	[HouseholdOccupationAtAge14.IPreferNotToSay]: '',
};
