import React, { FC } from 'react';

import { LightBulbIcon } from '../Icons/Lightbulb';

export const MyPreferencesCard: FC = () => (
	<>
		<div className="card">
			<div className="card__heading orange with-icon">
				<h2>My Preferences</h2>
				<LightBulbIcon />
			</div>
			<div className="my-diversity-card card__content">
				<p>
					We want to ensure the communications you receive are useful and
					relevant to you. Please take a moment to complete this page otherwise
					you will no longer receive valuable updates.
				</p>
			</div>
		</div>
	</>
);
