import React from 'react';

export const StarIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		id="Layer_1"
		x="0"
		y="0"
		version="1.1"
		viewBox="0 0 256 256"
		xmlSpace="preserve"
		width="4em"
		height="4em"
		aria-hidden="true"
	>
		<style />
		<defs>
			<path id="SVGID_1_" d="M0 0h256v256H0z" style={{ fill: '#f88d2a' }} />
		</defs>
		<clipPath id="SVGID_00000031888970184910828650000000210709400254976699_">
			<use overflow="visible" xlinkHref="#SVGID_1_" />
		</clipPath>
		<g
			id="Star"
			clipPath="url(#SVGID_00000031888970184910828650000000210709400254976699_)"
		>
			<path
				id="Path_17"
				d="M218.2 100.5c-3.6-1.1-7.3-3.3-11.2-3.3-9.8-.4-19.6.4-29.4 1.1-9.4.7-18.9 2.2-28.3 3.3-2.2-18.9-3.6-36.6-5.8-54.4-.7-7.6-.7-16-6.2-22.5-1.1 0-2.2-.4-3.3-.4-7.3-.7-10.9 1.4-14.2 8-5.8 12.3-12 24.7-17.4 37-6.5 14.9-13.1 30.1-19.6 45.4-2.9.4-5.4 1.1-8 .7-14.1-1.8-27.2 2.5-40.6 6.2-8.3 2.5-9.4 5.4-4.7 13.1 1.5 2.5 3.3 5.1 5.8 6.2 6.9 3.3 14.1 6.2 21.4 9.1 3.3 1.5 6.9 2.2 10.9 3.3-4 15.6-8 30.1-12 45-.7 3.3-1.8 6.9-1.5 10.2.4 5.4 1.8 11.2 7.3 13.8 5.4 2.9 9.8-1.4 13.8-4.7 1.3-1.1 2.5-2.3 3.6-3.6 7.6-8.3 14.9-17.1 22.5-25.4 4.4-4.7 9.1-9.1 14.2-14.1 2.2 3.6 3.6 5.8 5.1 8.3 9.1 15.6 18.9 30.5 32.7 42.5 2.2 1.8 4.4 4.4 7.3 5.8 5.4 2.9 9.8-.7 9.1-6.5-.4-1.4-.7-3.6 0-4 4-2.5 2.2-6.2 2.2-9.4s-.7-6.2-.7-9.4c-.4-16 0-32.3-1.5-48.3-.4-7.3 2.2-10.5 7.3-14.1 12.3-8.7 24.3-17.4 36.6-26.1 4.2-3.3 6.4-6.6 4.6-12.8zM155 136.1c.4 23.9 1.1 48.6 2.2 74.7-2.5-2.2-4-2.9-5.1-4.4-6.2-9.1-12-17.8-17.8-26.9-2.2-2.9-3.6-6.2-5.4-9.1-5.8-8.7-14.5-10.2-22.9-3.6-5.4 4.7-10.2 9.8-15.2 14.5s-9.8 9.8-14.5 14.5c-.4-.4-1.1-.7-1.5-1.1 1.5-5.1 2.5-10.5 4-15.6 2.2-8.3 4.7-16.3 6.9-24.3 1.4-5.8 1.1-6.9-4.7-9.1-6.9-2.9-14.2-5.1-21-7.6-4-1.5-8-3.3-12-4.7.3-.6.4-1.2.4-1.8 7.3-1.1 14.9-2.2 22.5-3.3 2.3-.3 4.6-.4 6.9-.4 12 .4 18.9-5.4 23.2-16.7 7.6-20.3 16.3-40.3 24.7-60.2.7-.7 1.1-1.8 2.9-4.7 1.8 13.8 3.3 25.8 5.4 38.1 1.1 6.9 2.2 13.4 3.6 20.3 1.5 7.3 5.8 9.4 13.1 8.7 12.7-1.5 25-2.5 39.9-4-11.6 10.7-24.3 16.9-35.6 26.7z"
				style={{ fill: '#f88d2a' }}
			/>
		</g>
	</svg>
);
