import React, { useEffect, useState } from 'react';

import { MenuItem } from '../App';
import { Options } from '../types/OptionSet';
import { arrayHasElements, isNotEmptyObject } from '../utils/validation';

import { useFetch } from './useFetch';

const memberMenuItems: MenuItem[] = [
	{ title: 'About Me', componentPath: '/about', default: true },
	{ title: 'My Work', componentPath: '/work' },
	{ title: 'My Diversity', componentPath: '/diversity', confirmed: false },
	{ title: 'My Preferences', componentPath: '/preferences' },
];

const studentMenuItems: MenuItem[] = [
	{ title: 'About Me', componentPath: '/about', default: true },
	{ title: 'My Diversity', componentPath: '/diversity', confirmed: false },
];

const publicMenuItems: MenuItem[] = [
	{ title: 'About Me', componentPath: '/about', default: true },
];

const isOptionData = (data: Record<string, unknown>): data is Options =>
	isNotEmptyObject(data) && 'icas_ethnicitycode' in data;

const userIsMember = (webRoles: string[]): boolean =>
	arrayHasElements(webRoles) && webRoles.includes('Members');

const userIsStudent = (webRoles: string[]): boolean =>
	arrayHasElements(webRoles) && webRoles.includes('Students');

export const useAppSetUp = (webRoles: string[]) => {
	const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
	const [options, setOptions] = useState<Options>({} as Options);
	const { data } = useFetch(`/lookup/options`);

	useEffect(() => {
		if (isOptionData(data)) {
			setOptions(data);
		}
	}, [data]);

	useEffect(() => {
		if (userIsMember(webRoles)) {
			setMenuItems(memberMenuItems);
		} else if (userIsStudent(webRoles)) {
			setMenuItems(studentMenuItems);
		} else {
			setMenuItems(publicMenuItems);
		}
	}, [webRoles]);

	return { menuItems, options };
};
