import { useCallback, useEffect, useRef, useState } from 'react';

const checkIfFieldExists = (name: string, currentFields: string[]): boolean => {
	if (currentFields.length) {
		const fieldExists = currentFields.includes(name);
		return fieldExists;
	}
	return false;
};

export const useInvalidFieldList = () => {
	const [invalidFields, setInvalidFields] = useState<string[]>([]);
	const invalidFieldsRef = useRef<string[]>([]);

	useEffect(() => {
		invalidFieldsRef.current = invalidFields;
	}, [invalidFields]);

	const handleFieldValidation = useCallback((name: string, valid: boolean) => {
		const currentInvalidFields = invalidFieldsRef.current;
		const fieldExistsInArray = checkIfFieldExists(name, currentInvalidFields);
		if (valid && fieldExistsInArray) {
			removeFromInvalidFields(name, currentInvalidFields);
		} else if (!valid && !fieldExistsInArray) {
			addToInvalidFields(name, currentInvalidFields);
		}
	}, []);

	const removeFromInvalidFields = (name: string, currentFields: string[]) => {
		const index = currentFields.indexOf(name);
		currentFields.splice(index, 1);
		setInvalidFields(currentFields);
	};

	const addToInvalidFields = (name: string, currentFields: string[]) => {
		currentFields.push(name);
		setInvalidFields(currentFields);
	};

	return { invalidFields, handleFieldValidation };
};
