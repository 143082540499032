import { useEffect, useState } from 'react';
import { LoadingStatus } from 'icas.core.reactcomponents';

import { SaveInfo } from '../../hooks/useHandleFormLogic';

export const useFeedbackInterval = (
	invalidSubmit: boolean,
	saveInfo: SaveInfo
) => {
	const [feedbackType, setFeedbackType] = useState<
		'Invalid' | 'Success' | 'Loading' | 'Failure' | undefined
	>();
	const { saveSent, saveState } = saveInfo;

	useEffect(() => {
		if (invalidSubmit) {
			setFeedbackType('Invalid');
		}
	}, [invalidSubmit]);

	useEffect(() => {
		const clearMessage = () => {
			setFeedbackType(undefined);
		};
		if (feedbackType && feedbackType !== 'Loading') {
			const timer = setTimeout(() => clearMessage(), 5000);
			return () => clearTimeout(timer);
		}
	}, [feedbackType]);

	useEffect(() => {
		if (saveSent) {
			if (saveState === LoadingStatus.LoadedSuccessfully) {
				setFeedbackType('Success');
			} else if (saveState === LoadingStatus.IsLoading) {
				setFeedbackType('Loading');
			} else if (saveState === LoadingStatus.EndedWithError) {
				setFeedbackType('Failure');
			}
		} else {
			setFeedbackType(undefined);
		}
	}, [saveSent, saveState]);

	return { feedbackType };
};
