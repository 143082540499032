import React, { FC, useEffect, useState } from 'react';
import {
	Select,
	Stack,
	TextInput,
	ValidationTextInput,
} from 'icas.core.reactcomponents';

import { useFetch } from '../../hooks/useFetch';
import { Country, ICASAddress } from '../../types/Address';
import {
	convertCountriesToSelectOptions,
	isPostcodeRequired,
	isValidSaveAddress,
} from '../../utils/AddressHelpers';
import { isNotEmptyObject } from '../../utils/validation';

import './AddressStyle.css';

type ManualAddressProps = {
	handleSubmit: (addr: ICASAddress) => void;
	invalidContainerSubmit?: boolean;
};

export const ManualAddress: FC<ManualAddressProps> = ({
	handleSubmit,
	invalidContainerSubmit = false,
}) => {
	const [address, setAddress] = useState<ICASAddress>({} as ICASAddress);
	const [isValid, setIsValid] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [invalidLocalSubmit, setInvalidLocalSubmit] = useState(false);
	const { data } = useFetch('/lookup/countries');

	useEffect(() => {
		const validAddress: boolean = isValidSaveAddress(address);
		setIsValid(validAddress);
	}, [address]);

	useEffect(() => {
		if (submitting) {
			if (isValid) {
				handleSubmit(address);
			} else {
				setInvalidLocalSubmit(true);
			}
		}
		return () => setSubmitting(false);
	}, [address, handleSubmit, isDirty, isValid, submitting]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setIsDirty(true);
		setInvalidLocalSubmit(false);
		const key = e.currentTarget.id;
		const value = e.currentTarget.value;
		setAddress(prevState => ({ ...prevState, [key]: value }));
	};

	const handleCountryChange = (_: string, value: string | number) => {
		const countries = [...(data as Country[])];
		const country = countries.find(c => c.id === value);
		if (country) {
			setAddress(prevState => ({
				...prevState,
				countryName: country.name,
				countryId: country.id,
			}));
		}
	};

	if (isNotEmptyObject(data)) {
		const postcodeRequired: boolean = isPostcodeRequired(address.countryName);

		const showError: boolean = invalidContainerSubmit || invalidLocalSubmit;

		return (
			<>
				<Stack as="div" gap={1} className="icas-address__manual">
					<ValidationTextInput
						value={address.line1}
						id="line1"
						labelText="Address Line 1"
						handleChange={handleChange}
						size={30}
						required
						vertical
						invalidSubmit={showError}
					/>
					<TextInput
						value={address.line2}
						id="line2"
						labelText="Address Line 2"
						handleChange={handleChange}
						size={30}
						vertical
					/>
					<TextInput
						value={address.line3}
						id="line3"
						labelText="Address Line 3"
						handleChange={handleChange}
						size={30}
						vertical
					/>
					<ValidationTextInput
						value={address.city}
						id="city"
						labelText="City/Town"
						handleChange={handleChange}
						required
						size={30}
						vertical
						invalidSubmit={showError}
					/>
					<Select
						options={convertCountriesToSelectOptions(data as Country[])}
						id="country"
						labelText="Country"
						selectedOption={address.countryId}
						handleChange={handleCountryChange}
						vertical
						required
						invalidSubmit={showError}
					/>
					<ValidationTextInput
						value={address.postCode}
						id="postCode"
						labelText="Postcode/Zip"
						handleChange={handleChange}
						size={8}
						required={postcodeRequired}
						vertical
						invalidSubmit={postcodeRequired && showError}
					/>
				</Stack>
				<span
					onClick={() => setSubmitting(true)}
					onKeyDown={() => setSubmitting(true)}
					className="icas-address-container__link"
					role="button"
					tabIndex={0}
				>
					Confirm Address
				</span>
			</>
		);
	}
	return <></>;
};
