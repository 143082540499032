import React, { FC, useContext } from 'react';
import { Radio, Stack } from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import { HandleRadioChangeFunction } from '../../types/ChangeFunctions';
import { MyDiversityData } from '../../types/MyDiversityData';
import { HouseholdDescriptions } from '../../utils/HouseholdOccuptation';

import './CardStyle.css';

type SocialMobilityCardProps = {
	customer: MyDiversityData;
	handleChange: HandleRadioChangeFunction;
};

export const SocialMobilityCard: FC<SocialMobilityCardProps> = ({
	customer,
	handleChange,
}): JSX.Element => {
	const { icas_householdocc14code: options } = useContext(AppContext);

	if (options) {
		const radioButtons = options.map(opt => {
			const description = HouseholdDescriptions[opt.value as number];

			return (
				<Radio.Button
					id={opt.name}
					key={`${opt.name}key`}
					labelText={opt.name}
					description={description}
					value={opt.value ?? undefined}
				/>
			);
		});

		return (
			<Stack as="section" gap={2} className="card">
				<div className="card__heading cyan">
					<h2>Social mobility</h2>
				</div>
				<div className="card__content socialmobility-card">
					<Radio
						heading={
							'What was the occupation of your main household earner when you were about aged 14?'
						}
						name="householdOccupationAtAge14"
						selectedValue={customer.householdOccupationAtAge14}
						handleChange={handleChange}
					>
						<Radio.Description description="This question has been devised by the government&#39;s Social Mobility Commission and is recommended by Access Accountancy, the finance profession&#39;s campaign to widen access to the profession." />
						<Radio.ButtonContainer vertical style={{ gap: '24px' }}>
							{radioButtons}
						</Radio.ButtonContainer>
					</Radio>
				</div>
			</Stack>
		);
	}
	return <></>;
};
