import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
	AuthContext,
	FetchHelper,
	FetchResponse,
	LoadingStatus,
	SearchInput,
} from 'icas.core.reactcomponents';

import {
	AddressListItem,
	ICASAddress,
	NoResultsList,
	SearchInputType,
} from '../../types/Address';
import {
	blankAddress,
	convertAddressToSearchOption,
	convertToICASAddress,
	isAnAddressList,
	isAnICASOfficeList,
	isValidAddress,
	mapICASOfficeListToAddressList,
} from '../../utils/AddressHelpers';
import { responseCodeIsUnauth } from '../../utils/responseCodes';
import { isNotEmptyObject } from '../../utils/validation';

import { AddressDisplay } from './AddressDisplay';
import { ManualAddress } from './ManualAddress';

import './AddressStyle.css';

type AddressContainerProps = {
	labelText: string;
	handleAddressChange: (address: ICASAddress, id?: string) => void;
	onFieldValidation?: (id: string, valid: boolean) => void;
	urlToSearch: string;
	required?: boolean;
	invalidSubmit?: boolean;
};

export type AddressProps = {
	address?: ICASAddress;
};

const initialList = [] as AddressListItem[];

const noResultsList = [
	{
		Id: 'no results',
		Type: 'no results',
		Text: 'No results found',
	},
] as NoResultsList[];

export const AddressContainer: FC<AddressProps & AddressContainerProps> = ({
	address,
	labelText,
	handleAddressChange,
	onFieldValidation,
	urlToSearch,
	required = false,
	invalidSubmit = false,
}) => {
	const { handleUnauth } = useContext(AuthContext);
	const [_, setLoadingStatus] = useState(LoadingStatus.LoadedSuccessfully);
	const [isValid, setIsValid] = useState(true);
	const [addressMode, setAddressMode] = useState<SearchInputType>('ReadOnly');
	const [addressSearchTerm, setAddressSearchTerm] = useState('');
	const [addressList, setAddressList] = useState<
		AddressListItem[] | NoResultsList[]
	>(initialList);

	const handleResponse = useCallback(
		async (fetchResponse: FetchResponse): Promise<any> => {
			if (fetchResponse.isOK) {
				const data: any = fetchResponse.body;
				return data;
			}
			if (responseCodeIsUnauth(fetchResponse)) {
				setLoadingStatus(LoadingStatus.EndedWithError);
				handleUnauth();
			} else {
				setLoadingStatus(LoadingStatus.EndedWithError);
				//set generic message
			}
		},
		[handleUnauth]
	);

	useEffect(
		() => () => {
			typeof onFieldValidation === 'function' &&
				onFieldValidation(labelText, true);
		},
		[labelText, onFieldValidation]
	);

	useEffect(() => {
		const addressExists: boolean = isValidAddress(
			address ?? ({} as ICASAddress)
		);
		if (addressExists) {
			setAddressMode('ReadOnly');
			return setIsValid(true);
		}
		if (addressMode === 'ReadOnly') {
			setAddressMode('Search');
		}
		setIsValid(false);
	}, [address, addressMode]);

	useEffect(() => {
		const valid = isValid && addressMode === 'ReadOnly';
		typeof onFieldValidation === 'function' &&
			onFieldValidation(labelText, valid);
	}, [addressMode, onFieldValidation, isValid, labelText]);

	useEffect(() => {
		const addressSearch = async () => {
			const addressToSearch: string = urlToSearch + addressSearchTerm;
			const fetchResponse = await FetchHelper.get(
				addressToSearch,
				handleUnauth,
				true
			);
			const data = await handleResponse(fetchResponse);
			if (!data || !data.length) {
				setAddressList(noResultsList);
			} else if (isAnAddressList(data)) {
				setAddressList(data);
			} else if (isAnICASOfficeList(data)) {
				const addressOptions = mapICASOfficeListToAddressList(data);
				setAddressList(addressOptions);
			}
		};
		if (addressSearchTerm?.length >= 3) {
			addressSearch();
		}
		if (!addressSearchTerm || addressSearchTerm.length <= 2) {
			setAddressList([]);
		}
	}, [addressSearchTerm, handleResponse, handleUnauth, urlToSearch]);

	const handleAddressModeButton = (searchType: SearchInputType) => {
		if (searchType !== 'ReadOnly') {
			handleAddressChange(blankAddress);
		}
		setAddressMode(searchType);
	};

	const handleAddressSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAddressSearchTerm(e.currentTarget.value);
	};

	const handleAddressSelection = async (id: string | number) => {
		if (isAnAddressList(addressList)) {
			const selected = addressList.find(address => address.Id === id);
			if (selected) {
				//if address is not from dynamics we need to do further request (or requests)
				if (!selected.address) {
					return handleNonICASAddress(selected);
				}
				handleAddressChange(selected.address.address, selected.address.id);
				setAddressList([]);
				setAddressSearchTerm('');
			}
		}
	};

	const handleManualAddressSubmit = (address: ICASAddress) => {
		handleAddressChange(address);
		setAddressList([]);
	};

	const handleNonICASAddress = (selected: AddressListItem) => {
		if (selected.Type && selected.Type !== 'Address') {
			return getNonICASPostcodeAddressList(selected);
		}
		getNonICASAddress(selected);
	};

	const getNonICASPostcodeAddressList = async (
		selected: AddressListItem
	): Promise<void> => {
		//get postcode addresses and set new address list to search
		const id = encodeURI(selected.Id);
		const fetchUrl = `/lookup/address/?addressString=${id}`;
		const fetchResponse = await FetchHelper.get(
			fetchUrl.toString(),
			handleUnauth,
			true
		);
		const data = await handleResponse(fetchResponse);
		if (isAnAddressList(data)) {
			setAddressList(data);
		}
	};

	const getNonICASAddress = async (selected: AddressListItem) => {
		const fetchUrl = `/lookup/address/${selected.Id}`;
		const fetchResponse = await FetchHelper.get(
			fetchUrl.toString(),
			handleUnauth,
			true
		);
		const data = await handleResponse(fetchResponse);
		if (isNotEmptyObject(data)) {
			const address = convertToICASAddress(data);
			handleAddressChange(address);
			setAddressList([]);
		}
	};

	const clearAddressList = () => {
		setAddressList(initialList);
	};

	if (addressMode === 'ReadOnly') {
		return (
			<>
				<div className="icas-address__outer">
					<legend className="icas-address__legend">{labelText}</legend>
				</div>
				<AddressDisplay address={address} />
				<span
					onClick={() => handleAddressModeButton('Search')}
					onKeyDown={() => handleAddressModeButton('Search')}
					className="icas-address-container__link"
					role="button"
					tabIndex={0}
				>
					Update your address
				</span>
			</>
		);
	} else if (addressMode === 'Search') {
		const options = convertAddressToSearchOption(addressList);

		return (
			<span>
				<legend className="icas-address__legend">{labelText}</legend>
				{invalidSubmit && (
					<div className="invalidMessage__Address">
						Please provide a valid address
					</div>
				)}
				<SearchInput
					id="addressSearch"
					value={addressSearchTerm}
					handleChange={handleAddressSearchInput}
					placeholder="Please start typing your address"
					listOfOptions={options}
					labelText="search for your address"
					showLabel={false}
					searchType="Automatic"
					handleSelection={handleAddressSelection}
					aria-describedby="invalidMessage"
					clearOptions={clearAddressList}
					required={required}
					invalidSubmit={invalidSubmit}
				/>
				<button
					onClick={() => handleAddressModeButton('Manual')}
					className="icas-search-input__link"
				>
					Enter Manually
				</button>
			</span>
		);
	}
	if (addressMode === 'Manual') {
		return (
			<>
				<div className="icas-address__outer">
					<legend className="icas-address__legend">{labelText}</legend>
				</div>
				{invalidSubmit && (
					<div className="invalidMessage">
						Please confirm using the `&apos;`confirm address`&apos;` button
						below the address fields
					</div>
				)}
				<ManualAddress
					handleSubmit={handleManualAddressSubmit}
					invalidContainerSubmit={invalidSubmit}
				/>
			</>
		);
	}
	return <></>;
};
