import React, { createContext, FC, useMemo } from 'react';
import {
	Layout,
	LoadingLottie,
	LoadingStatus,
	PrivateRoute,
	useAuthContext,
} from 'icas.core.reactcomponents';
import { Navigate, Route, Routes } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import { ProfileMenu } from './components/Menu/ProfileMenu';
import { useAppSetUp } from './hooks/useAppSetUp';
import { AboutMe } from './pages/AboutMe/AboutMe';
import { MyDiversity } from './pages/MyDiversity/MyDiversity';
import { MyPrefences } from './pages/MyPreferences/MyPreferences';
import { MyWork } from './pages/MyWork/MyWork';
import { Options } from './types/OptionSet';
import { dataLoaded, pageLoaded } from './utils/validation';

import './custom.css';

export type MenuItem = {
	title:
		| 'Confirm details'
		| 'About Me'
		| 'My Work'
		| 'My Preferences'
		| 'My Diversity';
	componentPath: string;
	confirmed?: boolean | 'submitting' | 'saved';
	default?: true;
};

export type AppInfo = {
	menuItems: MenuItem[];
	appDataLoaded: boolean;
};

export const AppContext = createContext<Options & AppInfo>({
	icas_ethnicitycode: [],
	icas_gendercode: [],
	icas_workingstatuscode: [],
	icas_memberinterestscode: [],
	icas_worksectors: [],
	icas_employeecountcode: [],
	icas_personanumberofpartners: [],
	icas_workingpatterncode: [],
	icas_title: [],
	icas_religioncode: [],
	icas_householdocc14code: [],
	icas_transidentitycode: [],
	icas_caringresponsibilitiescode: [],
	icas_caringresponsibilitiesdetailcode: [],
	icas_disabilitycode: [],
	icas_sexualorientationcode: [],
	menuItems: [],
	appDataLoaded: false,
});
AppContext.displayName = 'App Context';

const loginPath =
	'/authentication/login?returnUrl=' +
	encodeURIComponent(window.location.toString());

export const App: FC = (): JSX.Element => {
	const { currentLoading, webRoles, userId } = useAuthContext();
	const { menuItems, options } = useAppSetUp(webRoles);
	let screen = <LoadingLottie style={{ height: '20em', marginTop: '5em' }} />;

	if (currentLoading === LoadingStatus.EndedWithError && !userId) {
		screen = (
			<>
				<Routes>
					<Route
						path="/"
						element={
							<PrivateRoute loginPath={loginPath} excludedRoles={['Staff']} />
						}
					>
						<Route path="/" element={<AboutMe />} />
					</Route>
				</Routes>
			</>
		);
	} else if (pageLoaded(currentLoading)) {
		screen = (
			<>
				<ProfileMenu />
				<Routes>
					<Route
						element={
							<PrivateRoute loginPath={loginPath} excludedRoles={['Staff']} />
						}
					>
						<Route path="/about" element={<AboutMe />} />
					</Route>
					<Route
						element={
							<PrivateRoute
								loginPath={loginPath}
								limitedRoles={['Members']}
								excludedRoles={['Staff']}
							/>
						}
					>
						<Route path="/preferences" element={<MyPrefences />} />
					</Route>
					<Route
						element={
							<PrivateRoute
								loginPath={loginPath}
								limitedRoles={['Members']}
								excludedRoles={['Staff']}
							/>
						}
					>
						<Route path="/work" element={<MyWork />} />
					</Route>
					<Route
						element={
							<PrivateRoute
								loginPath={loginPath}
								limitedRoles={['Members', 'Students']}
								excludedRoles={['Staff']}
							/>
						}
					>
						<Route path="/diversity" element={<MyDiversity />} />
					</Route>
					<Route path="/" element={<Navigate to="/about" />} />
					<Route path="*">{/* <ErrorNotFound /> */}</Route>
				</Routes>
			</>
		);
	}

	const appDataLoaded = dataLoaded(options, currentLoading);

	const contextValue = useMemo(
		() => ({
			...options,
			menuItems,
			appDataLoaded,
		}),
		[options, menuItems, appDataLoaded]
	);

	return (
		<Layout>
			<ErrorBoundary>
				<AppContext.Provider value={contextValue}>
					<div id="title-container">
						<h1 id="app-title">My ICAS</h1>
					</div>
					<div className="app-container">{screen}</div>
				</AppContext.Provider>
			</ErrorBoundary>
		</Layout>
	);
};
