import React, { FC, useContext, useState } from 'react';
import {
	AuthContext,
	Error,
	LoadingLottie,
	LoadingStatus,
} from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import { DiversityQuestionsCard } from '../../components/Card/DiversityQuestionsCard';
import { MyDiversityCard } from '../../components/Card/MyDiversityCard';
import { SocialMobilityCard } from '../../components/Card/SocialMobilityCard';
import { FeedbackBar } from '../../components/FeedbackBar/FeedbackBar';
import { SaveBar } from '../../components/SaveBar/SaveBar';
import { useFetch } from '../../hooks/useFetch';
import { useHandleFormLogic } from '../../hooks/useHandleFormLogic';
import { MyDiversityData } from '../../types/MyDiversityData';
import { dataLoaded } from '../../utils/validation';

import '../ContainerStyle.css';

export const MyDiversity = () => {
	//#region State and Context Management
	const { userId } = useContext(AuthContext);
	const [postUrl] = useState(`/diversity/${userId}`);
	const { loadingStatus, data, postData } = useFetch(`/diversity/${userId}`);
	const {
		newData,
		isDirty,
		handleTextChange,
		handleSelectChange,
		handleRadioButtonChange,
		setFormAction,
		handleFieldValidation,
		saveInfo,
		invalidSubmission,
	} = useHandleFormLogic(data as MyDiversityData, postData, postUrl);

	//#endregion

	//#region Request Handlers

	if (dataLoaded(newData, loadingStatus)) {
		return (
			<form>
				<MyDiversityCard />
				<DiversityQuestionsCard
					customer={newData as MyDiversityData}
					handleSelectChange={handleSelectChange}
					handleTextChange={handleTextChange}
					handleFieldValidation={handleFieldValidation}
					invalidSubmit={invalidSubmission}
				/>
				<SocialMobilityCard
					customer={newData as MyDiversityData}
					handleChange={handleRadioButtonChange}
				/>
				<>
					<SaveBar handleFormAction={setFormAction} />
					{/* <Prompt
							when={isDirty}
							message={`You have unsaved changes on this page. Press OK to discard changes or cancel to return to the page`}
						/> */}
				</>
				<FeedbackBar invalidSubmit={invalidSubmission} saveInfo={saveInfo} />
			</form>
		);
	} else if (loadingStatus === LoadingStatus.EndedWithError) {
		return <Error />;
	} else if (loadingStatus === LoadingStatus.IsLoading) {
		return <LoadingLottie style={{ height: '20em', marginTop: '5em' }} />;
	}
	return <></>;
};
