import React, { FC, useContext } from 'react';
import {
	ReadOnlyTextInput,
	Select,
	Stack,
	TextInput,
	ValidationTextInput,
} from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import { useHandleCustomTitle } from '../../hooks/useHandleCustomTitle';
import { AboutMeData } from '../../types/AboutMeData';
import {
	HandleFieldValidationFunction,
	HandleSelectChangeFunction,
	HandleTextChangeFunction,
} from '../../types/ChangeFunctions';
import { RewardIcon } from '../Icons/Rewards';

import './CardStyle.css';

type PersonalInfoCardProps = {
	customer: AboutMeData;
	handleTextChange: HandleTextChangeFunction;
	handleSelectChange: HandleSelectChangeFunction;
	handleFieldValidation: HandleFieldValidationFunction;
	invalidSubmit?: boolean;
};

export const PersonalInfoCard: FC<PersonalInfoCardProps> = ({
	customer,
	handleTextChange,
	handleSelectChange,
	handleFieldValidation,
	invalidSubmit = false,
}): JSX.Element => {
	const { icas_title } = useContext(AppContext);
	const { titles, value, handleTitleChange } = useHandleCustomTitle(
		icas_title,
		customer.title,
		handleSelectChange
	);

	return (
		<Stack gap={2} as="section" className="card">
			<div className="card__heading cyan with-icon">
				<h2>Personal info</h2>
				<RewardIcon />
			</div>
			<Stack
				direction="row"
				gap={4}
				as="div"
				wrap="wrap"
				className="card__content personalinformation-card"
			>
				<Select
					id="title"
					options={titles}
					handleChange={handleTitleChange}
					onFieldValidation={handleFieldValidation}
					labelText="Title"
					selectedOption={value}
					invalidSubmit={invalidSubmit}
					vertical
					required
				/>
				<ValidationTextInput
					id="knownAs"
					value={customer.knownAs}
					labelText="Known As"
					handleChange={handleTextChange}
					size={10}
					onFieldValidation={handleFieldValidation}
					invalidSubmit={invalidSubmit}
					vertical
					required
				/>
				<ValidationTextInput
					id="lastName"
					value={customer.lastName}
					labelText="Last Name"
					handleChange={handleTextChange}
					size={20}
					onFieldValidation={handleFieldValidation}
					invalidSubmit={invalidSubmit}
					vertical
					required
				/>
				<TextInput
					id="designatoryLetters"
					value={customer.designatoryLetters}
					labelText="Designatory Letters"
					handleChange={handleTextChange}
					vertical
					size={5}
				/>
			</Stack>
			<hr />
			<div className="card__read-only">
				<p
					style={{
						fontSize: '0.9em',
						marginBottom: '0.5em',
						marginTop: '1.1em',
					}}
				>
					Please contact <a href="mailto:members@icas.com">ICAS</a> if you wish
					to update the following fields:
				</p>
				<div className="card__content personalinformation-card read-only">
					<ReadOnlyTextInput
						id="firstName"
						value={customer.firstName}
						labelText="First Name"
						size={customer.firstName?.length || 5}
					/>
					<ReadOnlyTextInput
						id="middleName"
						value={customer.middleName}
						labelText="Middle Name"
						size={customer.middleName?.length || 5}
					/>
				</div>
			</div>
		</Stack>
	);
};
