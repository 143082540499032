import React, { FC } from 'react';
import { Stack } from 'icas.core.reactcomponents';

import { HeartIcon } from '../Icons/Heart';

export const MyDiversityCard: FC = () => (
	<>
		<div className="card">
			<div className="card__heading cyan with-icon">
				<h2>My Diversity</h2>
				<HeartIcon />
			</div>
			<Stack gap={2} className="my-diversity-card card__content">
				<p>
					Collecting diversity information enables ICAS to assess how it
					understands, meets and supports the needs of our diverse Member base.
					It enables us to be transparent with our membership and the wider
					world on where we have gaps and importantly, how we are going to
					address them.
				</p>
				<p>
					As we seek to make the profession more welcoming, inclusive and to
					improve access to those from underrepresented backgrounds, including
					socio-economic status, it is important to have a full set of data to
					be able to review the experience and progression of our membership.
				</p>
				<p>
					Any data which is provided will be used by ICAS for a range of
					reporting and analysis purposes. While the data will not be stored
					anonymously, it will be held securely at all times and will only be
					accessible to a very small number of ICAS employees. Any reporting of
					the data – whether within ICAS or to third parties – will always be
					done anonymously.
				</p>
				<p>
					If you are happy to consent to ICAS processing your information for
					these purposes then please complete the questions below. If you do not
					want to provide the information then please move on to the next
					section.
				</p>
				<p>
					Where you have previously provided data to ICAS, we have pre-populated
					some of the responses to reduce the amount of your time which is
					required. If you decide that you no longer want ICAS to process any of
					this information set out below then please change the response to
					&#39;prefer not to say&#39;.
				</p>
				<p>
					Thank you for taking the time to complete this information. If you
					have any questions, or would like to know more about how this data
					would be used by ICAS, please{' '}
					<a
						href="https://www.icas.com/contact-us"
						target="_blank"
						rel="noreferrer"
					>
						contact us.
					</a>
				</p>
			</Stack>
		</div>
	</>
);
