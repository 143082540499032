import { LoadingStatus } from 'icas.core.reactcomponents';
//#region type validation

export const isValidString = (
	possibleString: unknown
): possibleString is string =>
	typeof possibleString === 'string' && possibleString.length > 0;

export const isEmptyString = (
	possibleString: unknown
): possibleString is string =>
	typeof possibleString === 'string' && possibleString.length === 0;

export const isNumber = (possibleNumber: unknown): possibleNumber is number =>
	typeof possibleNumber === 'number';

export const isNumberString = (possibleNumber: string): boolean => {
	const numberRegex = /^[0-9]*$/;
	return numberRegex.test(possibleNumber);
};

export const isUndefined = (
	possibleUndefined: unknown
): possibleUndefined is undefined => typeof possibleUndefined === 'undefined';

export const isNotUndefined = (possibleUndefined: unknown): boolean =>
	typeof possibleUndefined !== 'undefined';

export const isNotEmptyObject = (object: Record<string, unknown>): boolean => {
	const hasKeys: boolean = Object.keys(object).length > 0;
	if (hasKeys) {
		const hasValues: boolean = Object.values(object).some(
			value => value !== null && isNotUndefined(value)
		);
		return hasKeys && hasValues;
	}
	return hasKeys;
};

//#endregion

//#region rendering checks

export const pageLoaded = (currentLoading: LoadingStatus): boolean =>
	currentLoading === LoadingStatus.EndedWithError ||
	currentLoading === LoadingStatus.LoadedSuccessfully;

export const userLoaded = (
	userId: string | undefined,
	loadingStatus: LoadingStatus
): boolean =>
	isValidString(userId) && loadingStatus === LoadingStatus.IsNotLoading;

export const pageAndUserLoaded = (
	userId: string | undefined,
	loadingStatus: LoadingStatus
): boolean =>
	loadingStatus !== LoadingStatus.IsLoading &&
	loadingStatus !== LoadingStatus.EndedWithError &&
	isValidString(userId);

export const dataLoaded = (
	data: Record<string, unknown>,
	loadingStatus: LoadingStatus
): boolean =>
	loadingStatus !== LoadingStatus.IsLoading && isNotEmptyObject(data);

export const arrayHasElements = (array: unknown[] | undefined): boolean =>
	!isUndefined(array) && array.length > 0;
