import { useCallback, useContext, useEffect, useState } from 'react';
import { LoadingStatus } from 'icas.core.reactcomponents';

import { AppContext, MenuItem } from '../../App';
import { useCurrentPath } from '../../hooks/useCurrentPath';
import { SaveInfo } from '../../hooks/useHandleFormLogic';
import { useInvalidFieldList } from '../../hooks/useInvalidFieldList';
import {
	HandleNestedOrgChangeFunction,
	HandleNewEmployerFunction,
	HandleSelectChangeFunction,
	HandleTextChangeFunction,
	HandleWorkAddressChangeFunction,
} from '../../types/ChangeFunctions';
import { CompanyDetails, MyWorkData } from '../../types/MyWorkData';
import {
	isNotEmptyObject,
	isNumber,
	isNumberString,
	isValidString,
} from '../../utils/validation';

export type PostDataFunction = (
	data: Record<string, unknown>,
	fetchUrl: string
) => Promise<{
	success: LoadingStatus;
	returnedData: unknown;
}>;

const isMyWorkData = (data: Record<string, unknown>): data is MyWorkData =>
	isNotEmptyObject(data) && 'organisation' in data;

const formatEmployerName = (name: string): string => {
	if (name.includes('Head Office')) {
		return name.split('- Head Office')[0].trim();
	}
	return name;
};

const noSave: SaveInfo = {
	saveSent: false,
	saveState: LoadingStatus.IsNotLoading,
};

export const useWorkForm = (
	data: MyWorkData,
	postData: PostDataFunction,
	postUrl: string
) => {
	const { menuItems } = useContext(AppContext);
	const [currentMyWorkData, setCurrentMyWorkData] = useState<MyWorkData>(
		{} as MyWorkData
	);
	const [newMyWorkData, setNewMyWorkData] = useState<MyWorkData>(
		{} as MyWorkData
	);
	const [isDirty, setIsDirty] = useState(false);
	const [formAction, setFormAction] = useState('');
	const [saveInfo, setSaveInfo] = useState<SaveInfo>(noSave);
	const [invalidSubmission, setInvalidSubmission] = useState(false);
	const { invalidFields, handleFieldValidation } = useInvalidFieldList();
	const location = useCurrentPath();

	useEffect(() => {
		if (isMyWorkData(data)) {
			setCurrentMyWorkData(data);
			setNewMyWorkData(data);
		}
	}, [data]);

	useEffect(() => {
		if (invalidSubmission) {
			const clearSubmission = setTimeout(
				() => setInvalidSubmission(false),
				3000
			);

			return () => {
				clearTimeout(clearSubmission);
			};
		}
	}, [invalidSubmission]);

	useEffect(() => {
		if (saveInfo.saveState === LoadingStatus.EndedWithError) {
			const clearSubmission = setTimeout(
				() =>
					setSaveInfo({
						saveSent: false,
						saveState: LoadingStatus.IsNotLoading,
					}),
				3000
			);

			return () => {
				clearTimeout(clearSubmission);
			};
		}
	}, [saveInfo.saveState]);

	const handleFormSubmit = useCallback(() => {
		setInvalidSubmission(false);
		const submitForm = async () => {
			setSaveInfo({
				saveSent: true,
				saveState: LoadingStatus.IsLoading,
			});
			const { success: saveStatus } = await postData(newMyWorkData, postUrl);
			if (saveStatus === LoadingStatus.LoadedSuccessfully) {
				const data = { ...newMyWorkData };
				setCurrentMyWorkData(data);
				setIsDirty(false);
			}
			setSaveInfo({
				saveSent: true,
				saveState: saveStatus,
			});
		};

		if (!invalidFields.length) {
			if (isDirty) {
				submitForm();
			}
		} else {
			setInvalidSubmission(true);
		}
	}, [invalidFields.length, isDirty, newMyWorkData, postData, postUrl]);

	const handleFormReset = useCallback(() => {
		const oldData = { ...currentMyWorkData };
		setNewMyWorkData(oldData);
		setInvalidSubmission(false);
		setIsDirty(false);
	}, [currentMyWorkData]);

	useEffect(() => {
		if (formAction) {
			setInvalidSubmission(false);
			if (formAction === 'Submit') {
				handleFormSubmit();
			}
			if (formAction === 'Reset') {
				handleFormReset();
			}
		}
		return () => setFormAction('');
	}, [formAction, handleFormReset, handleFormSubmit]);

	const handleFormDirty = () => {
		setIsDirty(true);
		setSaveInfo(noSave);
	};

	//#endregion
	const handleSelectChange: HandleSelectChangeFunction = (fieldId, value) => {
		handleFormDirty();
		const id = fieldId as keyof typeof newMyWorkData;
		setNewMyWorkData(prevState => {
			const currentValue = prevState[id];
			if (currentValue) {
				if (isValidString(value) && isNumber(currentValue)) {
					const newValue = parseInt(value);
					return { ...prevState, [id]: newValue };
				}
			}
			if (isValidString(value) && isNumberString(value)) {
				const newValue = parseInt(value);
				return { ...prevState, [id]: newValue };
			}
			return { ...prevState, [id]: value };
		});
	};

	const handleTextChange: HandleTextChangeFunction = e => {
		handleFormDirty();
		const id = e.currentTarget.id as keyof typeof newMyWorkData;
		let newValue: number | string = e.currentTarget.value;
		const isTextField: boolean =
			e.currentTarget.classList.contains('icas-textfield');
		if (newValue) {
			//check if state should be a number first and parse value back to number if it should be for checking equivalence
			return setNewMyWorkData(prevState => {
				const currentValue = prevState[id];
				if (currentValue) {
					if (isValidString(newValue) && isNumber(currentValue)) {
						newValue = parseInt(newValue);
					}
				}
				if (
					isValidString(newValue) &&
					isNumberString(newValue) &&
					!isTextField
				) {
					newValue = parseInt(newValue);
				}
				return { ...prevState, [id]: newValue };
			});
		}
		setNewMyWorkData(prevState => ({ ...prevState, [id]: null }));
	};

	const handleNestedOrgChange: HandleNestedOrgChangeFunction = (id, value) => {
		handleFormDirty();
		const input = id as keyof CompanyDetails;
		setNewMyWorkData(prevState => {
			//check if state should be a number first and parse value back to number if it should be for checking equivalence
			const currentValue = prevState.organisation[input];
			if (currentValue) {
				if (isNumber(currentValue) && isValidString(value)) {
					value = parseInt(value);
				}
			} else {
				if (isValidString(value) && isNumberString(value)) {
					value = parseInt(value);
				}
			}
			return {
				...prevState,
				organisation: {
					...prevState.organisation,
					[input]: value,
				},
			};
		});
	};

	const handleAddressChange: HandleWorkAddressChangeFunction = (
		address,
		id?
	) => {
		handleFormDirty();
		setNewMyWorkData(prevState => ({
			...prevState,
			organisation: {
				...prevState.organisation,
				id: id,
				address: {
					...address,
				},
			},
		}));
	};

	const handleAddNewEmployerDetails: HandleNewEmployerFunction = (name, id) => {
		handleFormDirty();
		const formattedName = formatEmployerName(name);
		setNewMyWorkData(prevState => ({
			...prevState,
			organisation: {
				name: formattedName,
				parentCompanyId: id,
			},
		}));
	};

	return {
		newMyWorkData,
		isDirty,
		invalidSubmission,
		handleAddressChange,
		handleTextChange,
		handleSelectChange,
		handleNestedOrgChange,
		handleAddNewEmployerDetails,
		handleFieldValidation,
		setFormAction,
		saveInfo,
	};
};
