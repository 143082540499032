import React from 'react';

export const HeartIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		id="Layer_1"
		x="0"
		y="0"
		version="1.1"
		viewBox="0 0 256 256"
		xmlSpace="preserve"
		width="4em"
		height="4em"
		aria-hidden="true"
	>
		<style />
		<defs>
			<path id="SVGID_1_" d="M0 0h256v256H0z" style={{ fill: '#22b9d5' }} />
		</defs>
		<clipPath id="SVGID_00000037675352400363664020000009276311193480690311_">
			<use overflow="visible" xlinkHref="#SVGID_1_" />
		</clipPath>
		<g
			id="Heart_Heavy"
			clipPath="url(#SVGID_00000037675352400363664020000009276311193480690311_)"
		>
			<path
				id="Path_16"
				d="M22.2 197c-2.6 0-5-1.7-5.9-4.1-.7-2.1 0-4.4 1.8-5.7 9.7-7.7 19.7-15.5 29.3-23 14.7-11.5 29.9-23.4 44.6-35.4-7.5-5.8-14.1-12.6-19.7-20.1-7.5-10.4-10.6-20.2-9.3-29 .7-4.1 3.1-7.7 6.5-10 2.6-1.7 5.6-2.7 8.7-2.7 1 0 2 .1 3 .3 4.9 1 9.3 3.6 12.6 7.4 6.1-7.1 14.3-14.4 25.5-14.4.5 0 1.1 0 1.7.1 7.8.5 13.8 3.9 17.2 9.9 4.5 7.8 4.2 19.4-.8 29.6-5.9 11.9-15.7 21.2-24.2 28.2 6.4 3.5 12.9 6.7 19.7 9.4 14.6 6.3 29.7 11.5 45.1 15.4 19.3 4.4 38.9 6.8 58.7 7.2 3.2 0 5.8 2.6 5.8 5.8v.3c.1 3.1-2.3 5.7-5.4 5.8h-.3c-27.3-.5-54.3-4.7-80.5-12.4-10.2-3.4-20.2-7.3-29.9-11.7-8.1-3.3-16-7.2-23.5-11.6-1.5 1.1-2.9 2.2-4.4 3.3-3.4 2.5-6.5 4.9-9.6 7.3l-62.7 49c-1.3.6-2.6 1.1-4 1.1zM79.3 79.8c-.6 0-1.2.1-1.8.3-1.2.5-2.2 1.4-2.7 2.6-.2.8.7 3.6 1 4.5l.2.7c2.7 8 7.1 15.3 12.8 21.5 3.8 4.2 8.1 7.9 12.7 11.2.5-.4 1.1-.9 1.6-1.3 1.2-1 2.4-1.9 3.5-2.9 8.9-7.6 23.8-23.3 23.2-34.2-.2-3.3-1.8-5.9-4.9-8-1.7-1.2-3.8-1.9-5.9-1.9-8.4 0-16.9 10.9-20.5 15.6-1.2 1.8-3.2 2.9-5.3 2.9-2.2 0-4.2-1.1-5.3-3-1.6-3.1-3.9-5.8-6.8-7.8-.5 0-1.1-.2-1.8-.2z"
				style={{ fill: '#22b9d5' }}
			/>
		</g>
	</svg>
);
