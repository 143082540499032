import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
	AuthContext,
	FetchHelper,
	FetchResponse,
	ReadOnlyTextInput,
	SearchInput,
	Stack,
	TextInput,
} from 'icas.core.reactcomponents';

import { SearchInputType } from '../types/Address';
import { renderCapitalisedString } from '../utils/formatting';

type EmployerSearchProps = {
	labelText: string;
	urlToSearch: string;
	currentEmployer: string | undefined;
	handleEmployerChange: (name: string, id?: string) => void;
	onFieldValidation: (id: string, valid: boolean) => void;
	required: boolean;
	invalidSubmit: boolean;
};

type EmployerListItem = {
	id: string;
	text: string;
	city?: string;
};

const isAnEmployerList = (
	listToCheck: unknown
): listToCheck is EmployerListItem[] => {
	if (Array.isArray(listToCheck)) {
		return listToCheck.every(
			employer => 'id' in employer && 'text' in employer
		);
	}
	return false;
};

const handleEmployerList = (
	employers: EmployerListItem[]
): EmployerListItem[] => {
	const employersNeedFormatting = checkIfMultipleParentOffices(employers);
	if (employersNeedFormatting) {
		return addHeadOfficeText(employers);
	}
	return employers;
};

const checkIfMultipleParentOffices = (
	employers: readonly EmployerListItem[]
): boolean =>
	new Set(employers.map(employer => employer.text)).size !== employers.length;

const checkForSpecificMatchingEmployer = (
	employers: readonly EmployerListItem[],
	employerName: string,
	index: number
): boolean =>
	employers.some(
		(possibleMatchingEmployer, i) =>
			possibleMatchingEmployer.text === employerName && i !== index
	);

const addHeadOfficeText = (
	employers: readonly EmployerListItem[]
): EmployerListItem[] => {
	const formattedEmployers: EmployerListItem[] = employers.map(
		(employer, index) => {
			const matchingEmployer = checkForSpecificMatchingEmployer(
				employers,
				employer.text,
				index
			);
			if (matchingEmployer) {
				if (employer.city) {
					employer.text = `${
						employer.text
					} - Head Office: ${renderCapitalisedString(employer.city)}`;
				}
				return employer;
			}
			return employer;
		}
	);
	return formattedEmployers;
};

const noResultItem = {
	id: 'no results',
	text: 'No results found',
};

const emptyEmployerList: EmployerListItem[] = [];

export const Employer: FC<EmployerSearchProps> = ({
	labelText,
	urlToSearch,
	currentEmployer,
	handleEmployerChange,
	required,
	onFieldValidation,
	invalidSubmit,
}) => {
	const { handleUnauth } = useContext(AuthContext);

	const [searchMode, setSearchMode] = useState('ReadOnly');
	const [employerSearchTerm, setEmployerSearchTerm] = useState('');
	const [employerList, setEmployerList] =
		useState<EmployerListItem[]>(emptyEmployerList);
	const [isDirty, setIsDirty] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [isUserEditing, setIsUserEditing] = useState(false);

	const handleResponse = useCallback(
		async (fetchResponse: FetchResponse): Promise<any> => {
			if (fetchResponse.isOK) {
				const data: any = fetchResponse.body;
				return data;
			}
			if (
				fetchResponse.statusCode === 400 ||
				fetchResponse.statusCode === 500
			) {
				//TODO: something
			} else if (fetchResponse.statusCode === 401) {
				handleUnauth();
			} else {
				//set generic message
			}
		},
		[handleUnauth]
	);

	useEffect(() => {
		const employerSearch = async (addressToSearch: string) => {
			const url = urlToSearch + addressToSearch;
			const fetchResponse = await FetchHelper.get(url, handleUnauth, true);
			const data = await handleResponse(fetchResponse);
			if (!data || !data.length) {
				setEmployerList([noResultItem]);
			} else if (isAnEmployerList(data)) {
				const employers = handleEmployerList(data);
				setEmployerList(employers);
			}
		};
		const customerIsSearching: boolean =
			employerSearchTerm.length >= 3 && searchMode === 'Search';

		const customerIsRemovingSearch: boolean =
			employerSearchTerm.length <= 2 && searchMode === 'Search';

		if (customerIsSearching) {
			employerSearch(employerSearchTerm);
		}
		if (customerIsRemovingSearch) {
			setEmployerList(emptyEmployerList);
		}
	}, [
		employerSearchTerm,
		handleResponse,
		handleUnauth,
		searchMode,
		urlToSearch,
	]);

	useEffect(() => {
		if (invalidSubmit) {
			setIsDirty(false);
		}
	}, [invalidSubmit]);

	useEffect(() => {
		onFieldValidation('employer', isValid);
	}, [isValid, onFieldValidation]);

	useEffect(
		() => () => {
			onFieldValidation('employer', true);
		},
		[onFieldValidation]
	);

	useEffect(() => {
		if (currentEmployer) {
			if (!isUserEditing && searchMode !== 'ReadOnly') {
				setSearchMode('ReadOnly');
			}
			setIsValid(true);
		} else {
			if (searchMode === 'ReadOnly' && !isUserEditing) {
				setSearchMode('Search');
			}
			setIsValid(false);
		}
	}, [currentEmployer, isUserEditing, onFieldValidation, searchMode]);

	const handleEmployerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsDirty(true);
		setEmployerSearchTerm(e.currentTarget.value);
	};

	const handleEmployerSelection = (id: string | number) => {
		if (isAnEmployerList(employerList) && id !== noResultItem.id) {
			const selected = employerList.find(employer => employer.id === id);
			if (selected) {
				handleEmployerChange(selected.text, selected.id);
				setEmployerSearchTerm('');
				setEmployerList(emptyEmployerList);
				setIsUserEditing(false);
			}
		}
	};

	const handleManualEmployerBlur = () => {
		handleEmployerChange(employerSearchTerm);
		setEmployerSearchTerm('');
		setIsUserEditing(false);
	};

	const handleAnchorClick = (searchInput: SearchInputType) => {
		setSearchMode(searchInput);
		setIsDirty(true);
		setIsUserEditing(true);
		if (currentEmployer) {
			handleEmployerChange('');
		}
	};

	if (searchMode === 'ReadOnly') {
		return (
			<span>
				<ReadOnlyTextInput
					id="employer"
					value={currentEmployer ?? ''}
					labelText="Employer"
					vertical
				/>

				<button
					style={{ marginBottom: '2.5%', display: 'block' }}
					onClick={() => handleAnchorClick('Search')}
					className="icas-search-input__link"
				>
					Change employer
				</button>
			</span>
		);
	} else if (searchMode === 'Search') {
		return (
			<Stack as="div" gap={1}>
				<SearchInput
					id="employerSearch"
					searchType="Automatic"
					value={isDirty ? employerSearchTerm : currentEmployer}
					handleChange={handleEmployerInput}
					placeholder="Please search for your employer"
					listOfOptions={employerList}
					handleSelection={handleEmployerSelection}
					labelText={labelText}
					required
					invalidSubmit={invalidSubmit && !isDirty}
					clearOptions={() => setEmployerList(emptyEmployerList)}
				/>
				<button
					onKeyDown={() => handleAnchorClick('Manual')}
					onClick={() => handleAnchorClick('Manual')}
					className="icas-search-input__link"
				>
					I can&#39;t find my employer
				</button>
			</Stack>
		);
	}
	const showError =
		((invalidSubmit && !isDirty) || (isDirty && !isUserEditing)) && !isValid;
	return (
		<TextInput
			id="employerSearch"
			value={employerSearchTerm}
			handleChange={handleEmployerInput}
			handleBlur={handleManualEmployerBlur}
			hasError={showError}
			errorMessage="Please provide your employer's details"
			labelText="Please add your employer's name"
			placeholder="Please add your employer's name"
			size={40}
			showLabel={true}
			required
			vertical
		/>
	);
};
