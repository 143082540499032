import React, { useContext, useState } from 'react';
import {
	AuthContext,
	Error,
	LoadingLottie,
	LoadingStatus,
} from 'icas.core.reactcomponents';

import { CommunicationsCard } from '../../components/Card/CommunicationsCard';
import { CommunityCard } from '../../components/Card/CommunityCard';
import { MyPreferencesCard } from '../../components/Card/MyPreferencesCard';
import { FeedbackBar } from '../../components/FeedbackBar/FeedbackBar';
import { SaveBar } from '../../components/SaveBar/SaveBar';
import { useFetch } from '../../hooks/useFetch';
import { useHandleFormLogic } from '../../hooks/useHandleFormLogic';
import { PreferencesData } from '../../types/PreferencesData';
import { dataLoaded } from '../../utils/validation';

import '../ContainerStyle.css';

export const MyPrefences = () => {
	//#region State and Context Management
	const { userId } = useContext(AuthContext);
	const [postUrl] = useState(`/community/${userId}`);
	const { loadingStatus, data, postData } = useFetch(`/community/${userId}`);
	const {
		newData,
		isDirty,
		handleCheckboxChange,
		handleRadioButtonChange,
		handleMultiSelectChange,
		setFormAction,
		saveInfo,
		invalidSubmission,
	} = useHandleFormLogic(data as PreferencesData, postData, postUrl);

	//#endregion

	//#region Request Handlers

	const nData = newData as PreferencesData;

	if (dataLoaded(newData, loadingStatus)) {
		return (
			<form>
				<MyPreferencesCard />
				<CommunityCard
					handleChange={handleCheckboxChange}
					data={newData as PreferencesData}
					handleMultiSelectChange={handleMultiSelectChange}
					handleRadioChange={handleRadioButtonChange}
					doNotBulkEmail={nData.doNotBulkEmail}
				/>
				<CommunicationsCard data={nData} handleChange={handleCheckboxChange} />
				<>
					<SaveBar handleFormAction={setFormAction} />
					{/* <Prompt
							when={isDirty}
							message={`You have unsaved changes on this page. Press OK to discard changes or cancel to return to the page`}
						/> */}
				</>
				<FeedbackBar invalidSubmit={invalidSubmission} saveInfo={saveInfo} />
			</form>
		);
	} else if (loadingStatus === LoadingStatus.EndedWithError) {
		return <Error />;
	} else if (loadingStatus === LoadingStatus.IsLoading) {
		return <LoadingLottie style={{ height: '20em', marginTop: '5em' }} />;
	}
	return <></>;
};
