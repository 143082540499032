import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import { AuthProvider } from 'icas.core.reactcomponents';
import registerServiceWorker from './registerServiceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Router>
		<AuthProvider>
			<App />
		</AuthProvider>
	</Router>
);

registerServiceWorker();
