import React, { FC, useContext } from 'react';
import {
	LogRegion,
	Select,
	Stack,
	ValidationTextInput,
} from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import {
	HandleSelectChangeFunction,
	HandleTextChangeFunction,
} from '../../types/ChangeFunctions';
import { MyDiversityData } from '../../types/MyDiversityData';

import './CardStyle.css';

type DiversityQuestionsProps = {
	customer: MyDiversityData;
	handleSelectChange: HandleSelectChangeFunction;
	handleTextChange: HandleTextChangeFunction;
	handleFieldValidation: (name: string, valid: boolean) => void;
	invalidSubmit: boolean;
};

const CaringResponsibilities_Yes = 605340000;
const Religion_Other = 605340007;
const SexualOrientation_Other = 605340007;
const Gender_SelfDescribe = 605340003;

export const DiversityQuestionsCard: FC<DiversityQuestionsProps> = ({
	customer,
	handleSelectChange,
	handleTextChange,
	handleFieldValidation,
	invalidSubmit = false,
}): JSX.Element => {
	const {
		icas_caringresponsibilitiescode,
		icas_caringresponsibilitiesdetailcode,
		icas_disabilitycode,
		icas_religioncode,
		icas_gendercode,
		icas_transidentitycode,
		icas_sexualorientationcode,
		icas_ethnicitycode,
	} = useContext(AppContext);

	const showCaringResponsibilityDetail =
		customer.caringResponsibilities &&
		customer.caringResponsibilities === CaringResponsibilities_Yes;

	const showReligionDetail = customer.religion === Religion_Other;

	const showSexualOrientationDetail =
		customer.sexualOrientation === SexualOrientation_Other;

	const showGenderSelfDesribe = customer.genderCode === Gender_SelfDescribe;

	return (
		<Stack as="section" gap={2} className="card">
			<div className="card__heading cyan">
				<h2>Diversity questions</h2>
			</div>
			<Stack as="div" gap={2} className="card__content diversityquestion-card">
				<legend>Caring Responsibilities</legend>
				<Stack gap={2} as="div" className="diversityquestion-card__inner">
					<Select
						handleChange={handleSelectChange}
						labelText="Do you have caring responsibilities?"
						id="caringResponsibilities"
						selectedOption={customer.caringResponsibilities}
						options={icas_caringresponsibilitiescode}
						required
						vertical
					/>
					<LogRegion id="further- caring-responsibility-question">
						{showCaringResponsibilityDetail && (
							<Select
								handleChange={handleSelectChange}
								labelText="Further information about caring responsibilities"
								id="caringResponsibilitiesDetail"
								selectedOption={customer.caringResponsibilitiesDetail}
								options={icas_caringresponsibilitiesdetailcode}
								required
								vertical
							/>
						)}
					</LogRegion>
				</Stack>
				<legend>Disability</legend>
				<Select
					handleChange={handleSelectChange}
					labelText="Do you consider yourself disabled?"
					id="disability"
					selectedOption={customer.disability}
					explanatoryText="A person has a disability if they have a physical or mental impairment, and the impairment has a substantial and long-term adverse effect on his or her ability to carry out normal day to day activities’ (Equality Act, 2010, Section 6)."
					options={icas_disabilitycode}
					required
					vertical
				/>
				<legend>Religion</legend>
				<Stack gap={2}>
					<Select
						handleChange={handleSelectChange}
						labelText="What is your religion?"
						id="religion"
						selectedOption={customer.religion}
						options={icas_religioncode}
						required
						vertical
					/>
					<LogRegion id="religion-other-question">
						{showReligionDetail && (
							<ValidationTextInput
								id="religionOtherOption"
								value={customer.religionOtherOption}
								labelText="Please provide more detail"
								handleChange={handleTextChange}
								size={15}
								vertical
								invalidSubmit={invalidSubmit}
								required
								onFieldValidation={handleFieldValidation}
							/>
						)}
					</LogRegion>
				</Stack>

				<legend>Ethnicity</legend>
				<Select
					handleChange={handleSelectChange}
					labelText="Ethnic origin"
					id="ethnicCategoryCode"
					selectedOption={customer.ethnicCategoryCode}
					options={icas_ethnicitycode}
					required
					vertical
				/>
				<legend>Gender Identity</legend>
				<Select
					handleChange={handleSelectChange}
					labelText="What best describes your gender?"
					id="genderCode"
					selectedOption={customer.genderCode}
					options={icas_gendercode}
					required
					vertical
				/>
				<LogRegion id="gender-self-desribe">
					{showGenderSelfDesribe && (
						<ValidationTextInput
							id="genderSelfDescribe"
							value={customer.genderSelfDescribe}
							labelText="Please provide more detail"
							handleChange={handleTextChange}
							size={15}
							vertical
							invalidSubmit={invalidSubmit}
							required
							onFieldValidation={handleFieldValidation}
						/>
					)}
				</LogRegion>
				<Select
					handleChange={handleSelectChange}
					labelText="Is your gender identity the same as the sex you were assigned at birth?"
					id="transIdentity"
					selectedOption={customer.transIdentity}
					options={icas_transidentitycode}
					required
					vertical
				/>
				<legend>Sexual Orientation</legend>
				<Stack gap={2} className="diversityquestion-card__inner">
					<Select
						handleChange={handleSelectChange}
						labelText="What is your sexual orientation?"
						id="sexualOrientation"
						selectedOption={customer.sexualOrientation}
						options={icas_sexualorientationcode}
						required
						vertical
					/>
					<LogRegion id="sexual-orientation-question">
						{showSexualOrientationDetail && (
							<ValidationTextInput
								id="sexualOrientationDetail"
								value={customer.sexualOrientationDetail}
								labelText="Please provide more detail"
								handleChange={handleTextChange}
								size={15}
								vertical
								invalidSubmit={invalidSubmit}
								required
								onFieldValidation={handleFieldValidation}
							/>
						)}
					</LogRegion>
				</Stack>
			</Stack>
		</Stack>
	);
};
