import {
	AddressListItem,
	Country,
	ICASAddress,
	ICASOfficeListItem,
	NoResultsList,
	PostcodeAnywhereAddress,
} from '../types/Address';

import { OptionSet } from './../types/OptionSet';
import { isNotEmptyObject, isValidString } from './validation';

export const isValidAddress = (address: ICASAddress): boolean =>
	isNotEmptyObject(address) && isValidString(address.line1);

export const isAnAddressList = (
	listToCheck: unknown
): listToCheck is AddressListItem[] => {
	if (Array.isArray(listToCheck) && listToCheck.length) {
		return listToCheck.every(
			address =>
				'Id' in address &&
				'Text' in address &&
				'Type' in address &&
				address.Type !== 'no results'
		);
	}
	return false;
};

export const isAnICASOfficeList = (
	listToCheck: unknown
): listToCheck is ICASOfficeListItem[] => {
	if (Array.isArray(listToCheck) && listToCheck.length) {
		return listToCheck.every(
			address => 'id' in address && 'address' in address
		);
	}
	return false;
};

export const convertAddressToSearchOption = (
	addressList: AddressListItem[] | NoResultsList[]
): { id: string; text: string }[] | [] => {
	if (addressList.length) {
		return addressList.map(address => ({ id: address.Id, text: address.Text }));
	}
	return [];
};

export const convertToICASAddress = (
	postcodeAnywhereAddress: PostcodeAnywhereAddress
): ICASAddress => {
	const newAddress = {} as ICASAddress;
	newAddress.line1 = postcodeAnywhereAddress.Line1 ?? '';
	newAddress.line2 = postcodeAnywhereAddress.Line2 ?? '';
	newAddress.line3 = postcodeAnywhereAddress.Line3 ?? '';
	newAddress.city = postcodeAnywhereAddress.City ?? '';
	newAddress.county = postcodeAnywhereAddress.Provice ?? '';
	newAddress.countryName = postcodeAnywhereAddress.CountryName ?? '';
	newAddress.countryIso2 = postcodeAnywhereAddress.CountryIso2 ?? '';
	newAddress.postCode = postcodeAnywhereAddress.PostalCode ?? '';
	return newAddress;
};

export const renderAddressText = (addressObject: ICASAddress): string => {
	let addressText = '';
	if (addressObject) {
		if (addressObject.countryName) {
			addressText += `${addressObject.countryName} - `;
		}
		if (addressObject.line1) {
			addressText += `${addressObject.line1} - `;
		}
		if (addressObject.city) {
			addressText += `${addressObject.city} - `;
		}
		if (addressObject.postCode) {
			addressText += `${addressObject.postCode}`;
		}
	}
	return addressText;
};

export const mapICASOfficeListToAddressList = (
	data: ICASOfficeListItem[]
): AddressListItem[] => {
	const addressOptions: AddressListItem[] = data.map(address => {
		const text: string = renderAddressText(address.address);
		return {
			Id: address.id,
			Type: 'Address',
			Text: text,
			Description: null,
			address: address,
		};
	});
	return addressOptions;
};

export const blankAddress: ICASAddress = {
	line1: undefined,
	line2: undefined,
	postCode: undefined,
};

export const convertCountriesToSelectOptions = (
	countries: Country[]
): OptionSet[] =>
	countries.map(country => ({
		name: country.name,
		value: country.id,
	}));

const requiredPostcodeCountries = [
	'UK',
	'United Kingdom',
	'US',
	'United States',
	'Canada',
];

export const isPostcodeRequired = (countryName?: string): boolean => {
	if (countryName) {
		return requiredPostcodeCountries.includes(countryName);
	}
	return true;
};

export const isValidSaveAddress = (address: ICASAddress): boolean => {
	const postcodeNeeded = isPostcodeRequired(address.countryName);
	if (postcodeNeeded) {
		return (
			isNotEmptyObject(address) &&
			isValidString(address.line1) &&
			isValidString(address.city) &&
			isValidString(address.countryName) &&
			isValidString(address.postCode)
		);
	}
	return (
		isNotEmptyObject(address) &&
		isValidString(address.line1) &&
		isValidString(address.city) &&
		isValidString(address.countryName)
	);
};
