import React, { FC, useContext, useEffect } from 'react';
import {
	LogRegion,
	Select,
	Stack,
	ValidationTextInput,
	VisuallyHidden,
} from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import { WorkingStatus } from '../../enums/WorkingStatus';
import { MyWorkData } from '../../types/MyWorkData';
import { StarIcon } from '../Icons/Star';

import './CardStyle.css';

type MyRoleProps = {
	customer: MyWorkData;
	handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleSelectChange: (id: string, value: string | number) => void;
	handleFieldValidation: (name: string, valid: boolean) => void;
	notActive: boolean;
	invalidSubmit: boolean;
};

const showSector = (currentWorkingStatus: WorkingStatus): boolean =>
	[
		WorkingStatus.WorkinginBusiness,
		WorkingStatus.WorkinginPublicSectorNotforProfit,
		WorkingStatus.MaternityPaternityLeave,
		WorkingStatus.UnpaidVoluntaryWorkSupport,
	].includes(currentWorkingStatus);

export const MyRoleCard: FC<MyRoleProps> = ({
	customer,
	handleTextChange,
	handleSelectChange,
	handleFieldValidation,
	notActive,
	invalidSubmit = false,
}): JSX.Element => {
	const { icas_workingstatuscode, icas_worksectors, icas_workingpatterncode } =
		useContext(AppContext);

	const showJobTitleAndWorkPattern = !notActive;

	useEffect(() => {
		if (!showSector(customer.workingStatus ?? WorkingStatus.Unknown)) {
			if (typeof handleFieldValidation === 'function') {
				handleFieldValidation('workSector', true);
			}
		}
	}, [customer.workingStatus, handleFieldValidation]);

	return (
		<Stack gap={3} as="section" className="card">
			<div className="card__heading orange with-icon">
				<h2>My role</h2>
				<StarIcon />
			</div>
			<Stack
				gap={3}
				as="div"
				className="card__content membershipinformation-card"
			>
				<Stack
					gap={3}
					direction="row"
					wrap="wrap"
					as="div"
					className="membershipinformation-card__inner"
				>
					<Select
						handleChange={handleSelectChange}
						labelText="Working Status"
						id="workingStatus"
						selectedOption={customer.workingStatus}
						options={icas_workingstatuscode}
						invalidSubmit={invalidSubmit}
						required
						onFieldValidation={handleFieldValidation}
						vertical
					/>
					<LogRegion id="working-sector" aria-atomic={true}>
						{showSector(customer.workingStatus ?? WorkingStatus.Unknown) && (
							<>
								<VisuallyHidden>
									Further job information is required for your work status
								</VisuallyHidden>
								<Select
									handleChange={handleSelectChange}
									labelText="Working Sector"
									id="workSector"
									selectedOption={customer.workSector}
									options={icas_worksectors}
									invalidSubmit={invalidSubmit}
									required
									onFieldValidation={handleFieldValidation}
									vertical
								/>
							</>
						)}
					</LogRegion>
				</Stack>
				<LogRegion
					id="job-title-work-pattern"
					aria-atomic={true}
					className="membershipinformation-card__inner"
				>
					{showJobTitleAndWorkPattern && (
						<>
							<VisuallyHidden>
								Further job information is required for your work status
							</VisuallyHidden>
							<Stack
								gap={3}
								direction="row"
								wrap="wrap"
								as="div"
								className="membershipinformation-card__inner"
							>
								<ValidationTextInput
									id="jobTitle"
									value={customer.jobTitle}
									labelText="Job Title"
									handleChange={handleTextChange}
									size={15}
									invalidSubmit={invalidSubmit}
									required
									onFieldValidation={handleFieldValidation}
									vertical
								/>
								<Select
									handleChange={handleSelectChange}
									labelText="Working Pattern"
									explanatoryText="Part time is any working pattern working less than your organisation&#39;s full time hours"
									id="workingPattern"
									selectedOption={customer.workingPattern}
									options={icas_workingpatterncode}
									vertical
								/>
							</Stack>
						</>
					)}
				</LogRegion>
			</Stack>
		</Stack>
	);
};
