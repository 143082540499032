import { useCallback, useContext, useEffect, useState } from 'react';
import {
	AuthContext,
	FetchHelper,
	FetchResponse,
	LoadingStatus,
} from 'icas.core.reactcomponents';

import { customErrorInfo } from '../types/CustomErrorInfo';
import { responseCodeIsUnauth } from '../utils/responseCodes';
import { isNotEmptyObject, userLoaded } from '../utils/validation';

const isCustomErrorMessage = (data: any): data is customErrorInfo =>
	isNotEmptyObject(data) && 'errorField' in data && 'errorMessage' in data;

export const useFetch = (fetchUrl?: string) => {
	const { userId, handleUnauth } = useContext(AuthContext);
	const [data, setData] = useState({});
	const [errorInfo, setErrorInfo] = useState<customErrorInfo>();

	const [loadingStatus, setLoadingStatus] = useState(
		LoadingStatus.IsNotLoading
	);

	const handleResponse = useCallback(
		(fetchResponse: FetchResponse): void => {
			if (fetchResponse.isOK) {
				const data: any = fetchResponse.body;
				setLoadingStatus(LoadingStatus.LoadedSuccessfully);
				setData(data);
			} else {
				if (responseCodeIsUnauth(fetchResponse)) {
					setLoadingStatus(LoadingStatus.EndedWithError);
					handleUnauth();
				} else {
					setLoadingStatus(LoadingStatus.EndedWithError);
				}
			}
		},
		[handleUnauth]
	);

	const handlePostError = (fetchResponse: FetchResponse) => {
		//json.parse needed as handleresponse in icas.core.reactcomponents currently bringing back text
		const responseData: any = JSON.parse(
			fetchResponse.body as unknown as string
		);
		if (isCustomErrorMessage(responseData)) {
			setErrorInfo(responseData);
		}
	};

	const handlePostResponse = useCallback(
		(fetchResponse: FetchResponse): LoadingStatus => {
			let saveStatus = LoadingStatus.EndedWithError;
			if (fetchResponse.isOK) {
				return (saveStatus = LoadingStatus.LoadedSuccessfully);
			}
			if (responseCodeIsUnauth(fetchResponse)) {
				handleUnauth();
			} else {
				handlePostError(fetchResponse);
			}

			return saveStatus;
		},
		[handleUnauth]
	);

	const getData = useCallback(async () => {
		if (fetchUrl) {
			setLoadingStatus(LoadingStatus.IsLoading);
			const fetchResponse = await FetchHelper.get(
				fetchUrl.toString(),
				handleUnauth,
				true
			);
			handleResponse(fetchResponse);
		}
	}, [fetchUrl, handleResponse, handleUnauth]);

	useEffect(() => {
		if (userLoaded(userId, loadingStatus)) {
			getData();
		}
	}, [userId, getData, loadingStatus]);

	const postData = async (
		data: Record<string, unknown>,
		fetchUrl: string
	): Promise<{ success: LoadingStatus; returnedData: any }> => {
		let returnedData;
		setErrorInfo(undefined);
		const fetchResponse = await FetchHelper.post(
			fetchUrl.toString(),
			handleUnauth,
			data as unknown as BodyInit,
			true
		);
		const success: LoadingStatus = handlePostResponse(fetchResponse);
		if (success === LoadingStatus.LoadedSuccessfully) {
			returnedData = fetchResponse.body;
		}
		return { success, returnedData };
	};

	return { loadingStatus, data, errorInfo, handleResponse, postData };
};
