import React from 'react';

export const RewardIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		id="Layer_1"
		x="0"
		y="0"
		version="1.1"
		viewBox="0 0 256 256"
		xmlSpace="preserve"
		width="4em"
		height="4em"
		aria-hidden="true"
	>
		<style />
		<defs>
			<path id="SVGID_1_" d="M0 0h256v256H0z" style={{ fill: '#22b9d5' }} />
		</defs>
		<clipPath id="SVGID_00000090252356417220842180000013482605752264814990_">
			<use overflow="visible" xlinkHref="#SVGID_1_" />
		</clipPath>
		<g
			id="Reward"
			clipPath="url(#SVGID_00000090252356417220842180000013482605752264814990_)"
		>
			<path
				id="Path_65"
				d="M130.3 239.1c-.5-.1-1.1-.1-1.6 0-1 0-2-.3-2.8-.9-27.7-7-52.8-28.2-67.1-56.6C41.9 148 41.1 109 42.7 71.6c-.9-.3-1.5-1.1-1.6-2-.2-1.3.6-2.5 1.8-2.8 34.2-8.7 60.1-23.3 84.2-47.2 1.6-1.4 3.9-1.3 5.3.3.6.6.9 1.4 1 2.3 3.4 2.1 6.8 4.2 10.3 6.3 7.9 4.9 16.1 10 24.5 14.4 12 6.4 24.2 10.9 37.1 15.7 2.1.8 4.2 1.6 6.3 2.3 2.3.9 3.5 3.4 2.7 5.8-.3.7-.7 1.3-1.2 1.8.5.4.8 1 .8 1.7 1.9 37.8 3.8 76.9-13.5 111.4-14.2 28.3-39.3 49.5-67.1 56.6-.9.6-1.9.9-3 .9zm-83-167.8c.3 4.5.6 9.1.9 13.7 2 30 4.1 60.9 17 88.3 12.5 26.9 35.9 47.3 64.4 55.8 28.5-8.5 51.9-28.8 64.4-55.8 11.7-24.9 12.9-53.2 14.2-80.5.3-7.5.7-15.2 1.2-22.7 0-.2 0-.3.1-.5h-.3c-32.2-7.8-60.3-27.1-78.5-42.2C111.3 50.3 79.6 67 47.3 71.3zm100.8 115.2h-.8c-1.1 0-2.2-.5-2.9-1.3-1.2-1.5-2.3-3-3.5-4.5-1.4-1.9-2.9-3.8-4.5-5.7-1.8-2.2-3.6-4.2-5.5-6.2l-.4-.4c-7.6 6.3-13.5 11.2-18.9 16-.4.4-1 .6-1.6.6-1.1.3-2.2.2-3.2-.5s-1.6-1.7-1.7-2.9l.3-5.7c.7-13.2 1.4-26.8 2.9-40.1-10.8-8-15.9-21.6-13-34.8.1-.6.4-1.1.8-1.5 1.4-7.7 5.6-14.6 11.9-19.3 6.4-4.8 14.3-7 22.3-6.1 18.8 2.3 34.6 21.4 33.2 40.1-.7 8.4-5 16.1-11.8 21.1 1.8 15.6 2 31.3.6 47 0 1.2-.6 2.4-1.5 3.2-.6.7-1.6 1-2.7 1zm-34-47.5c.1 11.7-.3 23.5-.9 35 4-4.7 8.4-9 13.2-12.9 1.1-1 2.7-1.2 4.1-.7.4.1.8.2 1.1.4 4.7 3.2 9.1 6.9 13 11.1 1.1-9.8 1.8-20.3 2.1-33.5-7.3 3.7-15.6 5-23.6 3.6-3.1-.6-6.1-1.6-9-3zm-13.4-27.7c1.9 15.5 16.6 23.7 30.4 23.7 8 0 15.2-2.8 19.7-7.6 4.1-4.4 6-10.1 5.5-17.1-.9-14.4-14.2-28.2-27.9-29.1-.6 0-1.2-.1-1.9-.1-6.1-.1-11.9 2.4-16.2 6.7-5.1 5.5-7.8 12.7-7.6 20.2.1.9-.3 1.8-.9 2.4-.2.4-.6.7-1.1.9zm27.1 11c-1 0-2-.4-2.8-1.1-1.1-1.2-2.1-2.4-3.1-3.5-1.9-2.1-3.8-4.3-5.9-6.3-1-1-1.3-2.6-.8-3.9.5-1.4 1.8-2.4 3.3-2.4.7 0 1.4.2 1.9.7 3 2.1 5.8 4.6 8.2 7.5 2.8-5.5 6-10.8 9.5-15.9.9-1.3 2.6-1.6 3.9-.7 1.2.8 1.6 2.3.9 3.6-3.4 6.9-7.2 13.5-11.6 19.7l-.1.1c-.5 1.1-1.5 2-2.7 2.2h-.7z"
				style={{ fill: '#22b9d5' }}
			/>
		</g>
	</svg>
);
