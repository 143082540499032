/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import {
	AuthContext,
	Error,
	LoadingLottie,
	LoadingStatus,
	Modal,
} from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import { ContactInfoCard } from '../../components/Card/ContactInfoCard';
import { MembershipInfoCard } from '../../components/Card/MembershipInfoCard';
import { PersonalInfoCard } from '../../components/Card/PersonalInfoCard';
import { FeedbackBar } from '../../components/FeedbackBar/FeedbackBar';
import { SaveBar } from '../../components/SaveBar/SaveBar';
import { useFetch } from '../../hooks/useFetch';
import { useHandleFormLogic } from '../../hooks/useHandleFormLogic';
import { AboutMeData } from '../../types/AboutMeData';
import { dataLoaded } from '../../utils/validation';

import '../ContainerStyle.css';

export const AboutMe = () => {
	//#region State and Context Management

	const { userId } = useContext(AuthContext);
	const { appDataLoaded } = useContext(AppContext);
	const [postUrl] = useState(`/about/personal/${userId}`);

	const { loadingStatus, data, postData, errorInfo } = useFetch(
		`/about/personal/${userId}`
	);
	const {
		newData,
		isDirty,
		invalidSubmission,
		handleFieldValidation,
		handleTextChange,
		handleSelectChange,
		handleAddressChange,
		handleGAAMembershipChange,
		setFormAction,
		saveInfo,
	} = useHandleFormLogic(data as AboutMeData, postData, postUrl, errorInfo);

	//#endregion

	if (appDataLoaded && dataLoaded(newData, loadingStatus)) {
		return (
			<form noValidate>
				<FeedbackBar
					invalidSubmit={invalidSubmission}
					saveInfo={saveInfo}
					errorInfo={errorInfo}
				/>
				<PersonalInfoCard
					customer={newData as AboutMeData}
					handleSelectChange={handleSelectChange}
					handleTextChange={handleTextChange}
					handleFieldValidation={handleFieldValidation}
					invalidSubmit={invalidSubmission}
				/>
				<div className="container__inner-small">
					<ContactInfoCard
						customer={newData as AboutMeData}
						handleTextChange={handleTextChange}
						handleAddressChange={handleAddressChange}
						handleFieldValidation={handleFieldValidation}
						invalidSubmit={invalidSubmission}
					/>
					<MembershipInfoCard
						customer={newData as AboutMeData}
						handleTextChange={handleTextChange}
						handleFieldValidation={handleFieldValidation}
						handleGAAMembershipChange={handleGAAMembershipChange}
						invalidSubmit={invalidSubmission}
						errorInfo={errorInfo}
					/>
				</div>
				<>
					<SaveBar handleFormAction={setFormAction} />
					{/* {showModal && (
							<Modal id={'unsaved-about-changes'}>
								<Modal.Header>Unsaved changes</Modal.Header>
								<Modal.Body>
									You have unsaved changes on this page. Press OK to discard
									changes or cancel to return to the page
								</Modal.Body>
								<Modal.Actions />
							</Modal>
						)} */}
				</>
			</form>
		);
	} else if (appDataLoaded && loadingStatus === LoadingStatus.EndedWithError) {
		return <Error />;
	} else if (loadingStatus === LoadingStatus.IsLoading) {
		return <LoadingLottie style={{ height: '20em', marginTop: '5em' }} />;
	}
	return <></>;
};
