import React, { useContext } from 'react';
import {
	AuthContext,
	Error,
	LoadingLottie,
	LoadingStatus,
} from 'icas.core.reactcomponents';

import { AppContext } from '../../App';
import { MyRoleCard } from '../../components/Card/MyRoleCard';
import { WorkCard } from '../../components/Card/WorkCard';
import { FeedbackBar } from '../../components/FeedbackBar/FeedbackBar';
import { SaveBar } from '../../components/SaveBar/SaveBar';
import { WorkingStatus } from '../../enums/WorkingStatus';
import { useFetch } from '../../hooks/useFetch';
import { MyWorkData } from '../../types/MyWorkData';
import { dataLoaded } from '../../utils/validation';

import { useWorkForm } from './useWorkForm';

import '../ContainerStyle.css';

const nonActiveWorkingStatus = [
	WorkingStatus.CareerBreak,
	WorkingStatus.Retired,
	WorkingStatus.Unemployed,
];

export const MyWork = () => {
	//#region State and Context Management
	const { userId } = useContext(AuthContext);
	const postUrl = `/work/${userId}`;

	const { loadingStatus, data, postData } = useFetch(postUrl);
	const {
		newMyWorkData,
		isDirty,
		invalidSubmission,
		handleAddressChange,
		handleNestedOrgChange,
		handleTextChange,
		handleSelectChange,
		handleAddNewEmployerDetails,
		handleFieldValidation,
		setFormAction,
		saveInfo,
	} = useWorkForm(data as MyWorkData, postData, postUrl);

	if (dataLoaded(newMyWorkData, loadingStatus)) {
		const notActive: boolean = nonActiveWorkingStatus.includes(
			newMyWorkData.workingStatus ?? WorkingStatus.Unknown
		);

		return (
			<form>
				<MyRoleCard
					handleTextChange={handleTextChange}
					handleSelectChange={handleSelectChange}
					customer={newMyWorkData}
					notActive={notActive}
					invalidSubmit={invalidSubmission}
					handleFieldValidation={handleFieldValidation}
				/>
				{!notActive && (
					<WorkCard
						handleTextChange={handleTextChange}
						handleNestedOrgChange={handleNestedOrgChange}
						customer={newMyWorkData}
						handleAddressChange={handleAddressChange}
						handleEmployerChange={handleAddNewEmployerDetails}
						invalidSubmit={invalidSubmission}
						handleFieldValidation={handleFieldValidation}
					/>
				)}

				<>
					<SaveBar handleFormAction={setFormAction} />
					{/* <Prompt
							when={isDirty}
							message={`You have unsaved changes on this page. Press OK to discard changes or cancel to return to the page`}
						/> */}
				</>
				<FeedbackBar invalidSubmit={invalidSubmission} saveInfo={saveInfo} />
			</form>
		);
	} else if (loadingStatus === LoadingStatus.EndedWithError) {
		return <Error />;
	} else if (loadingStatus === LoadingStatus.IsLoading) {
		return <LoadingLottie style={{ height: '20em', marginTop: '5em' }} />;
	}
	return <></>;
};
