import React from 'react';

export const LightBulbIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		id="Layer_1"
		x="0"
		y="0"
		version="1.1"
		viewBox="0 0 256 256"
		xmlSpace="preserve"
		width="4em"
		height="4em"
		aria-hidden="true"
	>
		<style />
		<defs>
			<path id="SVGID_1_" d="M0 0h256v256H0z" style={{ fill: '#f88d2a' }} />
		</defs>
		<clipPath id="SVGID_00000039813164708266947460000013221430512872159620_">
			<use overflow="visible" xlinkHref="#SVGID_1_" />
		</clipPath>
		<g
			id="Lightbulb"
			clipPath="url(#SVGID_00000039813164708266947460000013221430512872159620_)"
		>
			<g id="Group_8" transform="translate(-257.521 -540.447)">
				<path
					id="Path_49"
					d="M439.5 651.7c-1.1-8.4-2.8-16.5-7.6-23.7-.3-.4-.4-.9-.7-1.3-2.9-4.4-5.6-8.9-9.8-12.2-2.6-2-5-4.3-7.6-6.3-2.5-2.1-5.6-3.5-8.8-4.2-2.3-.4-4.4-1.4-6.6-1.9-2.4-.7-4.8-1.2-7.2-1.4-5.1-.3-10.1-.3-15.2-.4 0-.2-.1-.3-.1-.4-1.4.4-2.8.7-4.2 1.1-1.9.6-3.7 1.5-5.6 1.8-3.1.4-6.1 1.4-8.9 2.9-2.6 1.4-5 3.2-7.8 4.2-1.5.5-2.9 1.4-3.9 2.7-.8 1-1.9 1.8-2.8 2.8-4.1 4.7-6.9 10.3-10.2 15.6-.2.5-.3.9-.4 1.4-1.7 4.5-3.2 9-3.1 13.9-1.9 4.9.1 9.7.6 14.6.5 5 4.5 8.7 5.6 13.6.1.4.7.7 1 1.1 1 1.3 2 2.6 2.8 4 2.6 5.1 5.8 10 9.5 14.4 1.3 1.7 2.4 3.5 3.4 5.4 2.8 4.5 4.9 9.3 8 13.6.3.4.7.8.7 1.2.2 2.5 2.3 3.9 3.6 5.7.9 1.2 1.8 2.3 2.9 3.3 2.3 2.6 4.5 5.3 7 7.6 3.3 3.1 7.6 4.6 11.9 5.7 4.1 1.1 8.5 1.4 12.6-.4 1-.4 2.3-1 2.7-1.9 1.2-2.4 3.1-4.2 4.6-6.4 2.9-4 5.7-8.1 6.8-13.1.9-3.3 2.1-6.6 3.5-9.7 1.6-4.3 4-8.2 6.9-11.8 4.9-5.8 9.7-11.6 11.8-19.1 1-3.5 2.4-6.8 3.3-10.3.7-2.5.6-5.2 1.1-7.7.3-1.5.4-3 .2-4.4zm-11.7 12c-2 6.6-5.1 12.8-9.2 18.3-3.7 5-7.6 9.9-10.6 15.4-1.4 2.4-2.5 5-3.5 7.6-1.5 4.1-3.5 8.1-4.9 12.2-1 3.1-3.5 5.4-6.6 6.2-1.3.4-2.6.4-3.8-.1-3.1-1-6.3-1.9-9.3-2.7-1.1-1.6-2.3-3-3.2-4.5-1-1.8-3-3.2-2.5-5.7-2.2-3.8-3.4-8.2-5.6-12-1.1-1.6-2.4-3.1-3.8-4.4-1.8-1.9-3.6-3.9-5.5-5.6-2.4-2.1-3.8-4.9-5.7-7.4-1.6-2.2-2.6-4.9-4.6-6.9-1-4.2-4.1-7.4-5.8-11.3-2.1-5-3.4-10.3-3.7-15.8-.1-3.4.7-6.9 1.4-10.2 1-4.4 3.3-8.3 5.6-12 1.3-2.2 3.6-3.8 5.7-5.5 2.4-2 5.2-3.5 7.7-5.2 2.5-1.7 5.8-2.3 8.7-3.5 5.6-2.3 11.4-2.5 17.4-2.3 5.1.1 10.2 1.1 14.9 3 7.4 3.1 14.1 7.1 18.8 13.8.7 1 1.3 1.9 2.1 2.8 3.5 4.5 5.5 9.7 7 15.1.2.4.4.9.4 1.4.1 6.6.6 12.9-1.4 19.3z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_50"
					d="M411.4 742.6c-1.4-1.5-3.5-2-5.4-1.5-1.8.2-3.6.2-5.4.1-1.6.1-3.3-.1-4.8.4-2.2.7-4.5 1-6.7 1-2.1 0-4.2.7-6.3.8-2.1.1-4.3.1-6.4-.1-1.6-.1-3.2-.5-4.9-.6-3.1-.1-5.8 2.3-5.9 5.5 0 .6.1 1.2.2 1.8 1 3.6 6 7 9.6 6.1 4.1-1 8.1-.2 12.1.2 3.8.4 7.5.5 11.3.5 2.1-.1 4.2.2 6.2.9 4.2 1.6 7.3-.3 8.4-4.7.7-3 .5-6-.5-8.9-.2-.4-.9-.9-1.5-1.5z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_51"
					d="M409.7 767.3c-.1-.6-.2-1.3-.6-1.8-1.5-2.4-3.5-3.9-6.6-3.4-1.6.4-3.3.5-4.9.3-2.6-.6-5.3-.6-7.8 0-4.2.9-8.4 1-12.7.4-.3 0-.7.1-1 .3-4.2 4.6-4.5 5.9-2.6 11.1 1.5.5 3.1.8 4.6.9 5.7-.1 11.1 1.3 16.5 2.6 2.3.5 4.4 1.3 6.7 0 .4-.2 1-.3 1.4-.1 2.3 1 3.8-.7 5.5-1.6 1.4-.7 2.1-2.3 1.7-3.8-.1-1.6-.2-3.2-.2-4.9z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_52"
					d="M433.8 595c2.6-.6 5.5-1.6 6.6-5.1 1.3-4 3.5-7.4 5.7-10.8 1.1-2 2.1-4.1 2.9-6.2.7-1.5.1-2.8-.9-4.1-1.1-1.2-2.7-2-4.3-2.1-1.4-.2-2.7.4-3.4 1.6l-12 18.6c-.1.2-.1.3-.2.5-1.5 3.4 1.4 7.8 5.6 7.6z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_53"
					d="M304 594.6c4 3.4 7.2 7.6 10.5 11.7.8 1 1.8 1.9 2.8 2.6 2 1.3 4.6 1.1 6.4-.3 1.7-1.9 1.8-4.7.2-6.7-.6-1-1.3-1.9-2.1-2.7-3.8-2.7-6.4-6.5-9.6-9.8-1.6-1.7-3.3-2.7-5.7-2.3-.8 0-1.6 0-2.4.2-.7.3-1.6.9-1.7 1.5-.3 2.1-.4 4.1 1.6 5.8z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_54"
					d="M343.2 579.2c.2 3.9 2.8 7.2 6.5 8.3 3.3 1 5.2-.2 5.2-3.6-.1-1.8-.3-3.6-.7-5.3-.4-1.4-.9-2.8-1.4-4.2-1.5-4.2-3.9-7.9-7.1-11-.6-.1-1.1-.2-1.5-.3-1.8-.3-3.2.1-4.1 1.8-.9 1.7-1.3 3.4.1 5 1.8 2.9 2.9 6.1 3 9.3z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_55"
					d="M462.3 624.9c6.3-.2 11.6-3.3 17.2-5.5 3.1-1.2 3.5-3 1.8-5.9-.8-1.9-2.9-3-4.9-2.6-1 .1-1.9.3-2.9.7-5.3 2.3-10.8 3.6-16.5 4.9-1.2 1.9-1.6 3.7-.2 5.9 1.4 2.2 3.1 2.6 5.5 2.5z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_56"
					d="M387 584.1c2.9 1.1 7.5.4 7.7-4 .2-3.8.6-7.5.9-11.8v-3.4c.1-1.8-.7-3-2.1-4.1-1.8-1.5-3.7-1-5.6-.5-1.7.4-2.8 2-2.7 3.7 0 1.6.4 3.3.3 4.9-.2 2.6-.6 5.2-1 7.8-.4 2.1 0 4.3 1 6.2.5.5 1 .9 1.5 1.2z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_57"
					d="M466.7 698c-1.1-.4-2.1-1-3-1.6-2.9-2.5-6.7-3.5-9.8-5.7-1.6-1-3.6-1.1-5.2-.1-1.1.7-2.1 1.4-3.1 2.3.3 4.2 2.4 6.1 5.6 7.4 2.5 1.2 5 2.5 7.4 4 1.2.8 2.6 1.5 4 2 1.4.4 2.9.6 4.3.4 1.8-.3 3.4-1.5 4-3.3.7-1.7-.8-2.7-1.8-3.8-.7-.8-1.5-1.3-2.4-1.6z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_58"
					d="M481.4 653.3c-.4-.5-.9-.9-1.5-1-2.2-.6-4.5-1-6.8-1.1-3.4 0-6.8.1-10.3.5-1.4.3-2.8.7-4.1 1.4-1.3.5-1.9 2-1.4 3.3.3 1.1.8 2.1 1.5 3.1.8 1.7 2.6 2.6 4.5 2.3 1.9-.2 3.9-.2 5.8-.1v-.4c2.3 0 4.6.1 6.9 0 1.1 0 2.3-.3 3.4-.7 3.6-1.4 4.5-4.5 2-7.3z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_59"
					d="M309.2 639.9c.8 0 1.6-.1 2.4-.2 1-.1 1.9-.8 2.2-1.8 1.1-2.2.6-4.2-1.7-5.3-4.4-2.1-8.9-4.1-14.3-3.7-2.3-.7-4.8-.5-7 .6-1.5.6-2.2 2.2-1.6 3.7.1.3.3.6.5.8 1.1 1.4 2.7 2.5 4.4 3 5 1.6 10 2.6 15.1 2.9z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_60"
					d="M455 715.2c-2.7-1.5-5.2-3.3-7.6-5.3-1.8-1.6-4.4-1.8-6.4-.4l-2.4 1.8c0 3.2 1.4 5.2 3.2 6.9 1.3 1.4 2.8 2.8 4.3 4.1 2.4 1.9 4.8 4 8.3 3.8 1.8-.1 3.2-.6 4.1-2.2 1-1.6 1-3.6-.1-5.1-.9-1.5-2-2.7-3.4-3.6z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_61"
					d="M330.4 699.5c-.5.1-1 .1-1.5.2-4.7 1.3-9.4 3-13.8 5.2-1.6.9-2.8 2.3-3.5 4.1.7 1.6 1.4 3.3 2.3 5.3.7.2 1.4.3 2.1.4.7.1 1.3-.1 1.9-.1 2.4.2 9.2-3.9 11.6-5.9 1.5-1.3 2.8-2.7 4-4.3.8-1.1.9-2.6.2-3.7-.5-1.2-2-1.7-3.3-1.2z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_62"
					d="M316.2 673.5c-.3-1.3-1.4-2.3-2.8-2.4-1.4-.4-2.9-.5-4.3-.2-1.4.4-2.9.4-4.4.7-3.5.8-7 1.6-10.4 2.7-1.6.5-2 1.5-1.6 3 .5 2.7 2.9 4.6 5.6 4.6 1.1.1 2.3 0 3.3 0 1.8-.1 3.4-.2 5.1-.4 3.2-.2 5.9-1.8 8.4-3.7 1.3-.9 1.7-2.7 1.1-4.3z"
					style={{ fill: '#f88d2a' }}
				/>
				<path
					id="Path_63"
					d="M370.7 622.9c-3.5-1.2-6.3-1.1-8.7 1.3-.7.7-1.8.7-2.6 1.3-1.9 1.3-3.7 2.7-5.3 4.3-3.5 3.9-4.6 9-6.5 13.6-.2.8-.2 1.6 0 2.4.1 1.1.4 2.3.4 3.4.1 4.5 2.4 8.1 4.8 11.6.9 1 1.9 1.8 3.2 2.3 1.9.9 4.1.4 5.4-1.1 1.3-1.3 1.5-3.4.6-5-.6-1.2-1.5-2.2-2-3.4-.7-1.8-1.7-3.8-1.6-5.6 0-3.1.6-6.2 1.9-9.1 1.2-2.8 3.3-5 6-6.2 1.3-.6 2.7-1.2 4-1.9 2.7-1.4 3.2-3 1.8-5.5-.4-.7-.9-1.5-1.4-2.4z"
					style={{ fill: '#f88d2a' }}
				/>
			</g>
		</g>
	</svg>
);
