//error boundarys cannot yet (react 17) be written as a functional component so must either be a class component
//or a package such as 'react-error-boundary' must be used

import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Error as ErrorForm } from 'icas.core.reactcomponents';

type Props = {
	children: ReactNode;
};

type State = {
	hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
	state: State = {
		hasError: false,
	};

	static getDerivedStateFromError(error: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// eslint-disable-next-line no-console
		console.error('Uncaught error:', error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <ErrorForm />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
