import React, { FC, useContext, useEffect, useState } from 'react';
import {
	AuthContext,
	ReadOnlyTextInput,
	Select,
	Stack,
	ValidationTextInput,
} from 'icas.core.reactcomponents';

import { useFetch } from '../../hooks/useFetch';
import { AboutMeData } from '../../types/AboutMeData';
import {
	HandleFieldValidationFunction,
	HandleSelectChangeFunction,
	HandleTextChangeFunction,
} from '../../types/ChangeFunctions';
import { customErrorInfo } from '../../types/CustomErrorInfo';
import { OptionSet } from '../../types/OptionSet';
import { isNotEmptyObject } from '../../utils/validation';

import './CardStyle.css';

export const noGAAMemberships: OptionSet = {
	name: 'No other professional memberships',
	value: 'no membership',
};

const isStudentEmail = (email: string): boolean =>
	email.includes('@student.icas.com');

const hasMemberWebRoles = (webRoles: string[]): boolean =>
	webRoles && webRoles.length > 0 && webRoles.includes('Members');

type GAAMember = {
	name: string;
	id: string;
};

const convertGAAMembersToSelectOptions = (
	gaaMembers: GAAMember[]
): OptionSet[] =>
	gaaMembers.map(gaaMember => ({
		name: gaaMember.name,
		value: gaaMember.id,
	}));

const arrangeGAAMembersOptions = (gaaMembers: GAAMember[]): OptionSet[] => {
	const options = convertGAAMembersToSelectOptions(gaaMembers);
	options.unshift(noGAAMemberships);
	return options;
};

type MembershipInfoProps = {
	customer: AboutMeData;
	handleTextChange: HandleTextChangeFunction;
	handleFieldValidation?: HandleFieldValidationFunction;
	handleGAAMembershipChange: HandleSelectChangeFunction;
	invalidSubmit?: boolean;
	errorInfo?: customErrorInfo;
};

const emailId = 'email';

export const MembershipInfoCard: FC<MembershipInfoProps> = ({
	customer,
	handleTextChange,
	handleFieldValidation,
	handleGAAMembershipChange,
	invalidSubmit = false,
	errorInfo,
}): JSX.Element => {
	const [studentEmail, setStudentEmail] = useState(false);
	const { webRoles } = useContext(AuthContext);
	const { data: gaaMembers } = useFetch('/lookup/otherGAAMembers');

	const isMember = hasMemberWebRoles(webRoles);
	const failSubmit = errorInfo?.errorField === emailId;

	useEffect(() => {
		if (customer.email) {
			const userHasStudentEmail = isStudentEmail(customer.email);
			setStudentEmail(userHasStudentEmail);
		} else {
			setStudentEmail(false);
		}
	}, [customer.email]);

	const studentEmailInput: JSX.Element = (
		<ReadOnlyTextInput
			id={emailId}
			value={customer.email}
			labelText="Primary Email"
			size={33}
			vertical
		/>
	);

	const nonStudentEmailInput: JSX.Element = (
		<ValidationTextInput
			id={emailId}
			value={customer.email}
			labelText="Primary Email"
			handleChange={handleTextChange}
			size={33}
			vertical
			required
			type="email"
			onFieldValidation={handleFieldValidation}
			invalidSubmit={invalidSubmit}
			failedSubmit={failSubmit}
		/>
	);

	if (isNotEmptyObject(gaaMembers)) {
		return (
			<Stack gap={3} as="section" className="card card-small">
				<div className="card__heading cyan">
					<h2>Membership info</h2>
				</div>
				<div className="card__content membershipinformation-card">
					{studentEmail ? studentEmailInput : nonStudentEmailInput}
					<ValidationTextInput
						id="secondaryEmail"
						value={customer.secondaryEmail}
						labelText="Second Email"
						handleChange={handleTextChange}
						size={33}
						vertical
						type="email"
						onFieldValidation={handleFieldValidation}
						invalidSubmit={invalidSubmit}
					/>
					{isMember && (
						<>
							<hr />
							<Select
								options={arrangeGAAMembersOptions(gaaMembers as GAAMember[])}
								id="gaaMembership"
								labelText="Global Accounting Alliance membership"
								selectedOption={
									customer.gaaMembership?.organisationId ??
									noGAAMemberships.value
								}
								handleChange={handleGAAMembershipChange}
								vertical
							/>
							<ReadOnlyTextInput
								id="caMemberNumber"
								value={customer.caMemberNumber}
								labelText="Membership Number"
								size={customer.caMemberNumber?.length || 6}
							/>
							<ReadOnlyTextInput
								id="joinDate"
								value={new Date(customer.joinDate).getFullYear().toString()}
								labelText="Member since"
								size={4}
							/>
						</>
					)}
				</div>
			</Stack>
		);
	}
	return <></>;
};
