import React, { useContext, useEffect, useRef, useState } from 'react';
import { ArrowType, Menu } from 'icas.core.reactcomponents';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppContext, MenuItem } from '../../App';

const useCurrentPath = () => {
	const location = useLocation();
	return location.pathname.replace('/', '');
};

export const renderMenuItems = (menuItems: MenuItem[]) =>
	menuItems.map((item, index) => {
		if (index + 1 !== menuItems.length) {
			return (
				<React.Fragment key={item.componentPath}>
					<Menu.RouterItem
						key={item.componentPath}
						componentPath={item.componentPath}
					>
						{item.title}
					</Menu.RouterItem>
					<Menu.Divider />
				</React.Fragment>
			);
		}
		return (
			<React.Fragment key={item.componentPath}>
				<Menu.RouterItem
					key={item.componentPath}
					componentPath={item.componentPath}
				>
					{item.title}
				</Menu.RouterItem>
			</React.Fragment>
		);
	});

export const ProfileMenu = () => {
	const { menuItems } = useContext(AppContext);
	const navigate = useNavigate();
	const listRef = useRef<HTMLUListElement>(null);
	const [currentText, setCurrentText] = useState('');
	const currentItemValue = useCurrentPath();

	const routerOptions = renderMenuItems(menuItems);

	useEffect(() => {
		const menuItems = [
			...(listRef?.current?.children ?? []),
		] as HTMLAnchorElement[];

		const currentItem = menuItems.find(item =>
			item.classList.contains('active')
		);
		setCurrentText(currentItem?.innerText ?? '');
	}, [currentItemValue]);

	const handleArrowClick = (plusOrMinus: string | number) => {
		// create array of only menu links e.g. filter out any dividers
		const menuItems = Array.from(listRef.current?.children ?? []).filter(
			child => child.nodeName === 'A'
		) as HTMLAnchorElement[];

		let index = menuItems.findIndex(item => item.classList.contains('active'));
		if (plusOrMinus === ArrowType.Minus) {
			if (index !== 0 && index !== -1) {
				index--;
			}
		} else if (plusOrMinus === ArrowType.Add) {
			const numberOfItems = menuItems.length - 1;
			if (index !== numberOfItems && index !== -1) {
				index++;
			}
		}
		const href = menuItems[index].href.split('/').pop();
		if (href) {
			return navigate(href);
		}
	};

	return (
		<div className="container__menu">
			<Menu
				id={'profile-menu'}
				currentItem={{ value: currentItemValue, text: currentText }}
				handleArrowClick={handleArrowClick}
			>
				<Menu.MobileButton />
				<Menu.ArrowLeft />
				<Menu.List ref={listRef}>{routerOptions}</Menu.List>
				<Menu.ArrowRight />
			</Menu>
		</div>
	);
};
