import React, { FC, useEffect } from 'react';
import { Stack, ValidationTextInput } from 'icas.core.reactcomponents';

import { AddressContainer } from '../../pages/Address/AddressContainer';
import { AboutMeData } from '../../types/AboutMeData';
import { ICASAddress } from '../../types/Address';
import {
	HandleFieldValidationFunction,
	HandleTextChangeFunction,
} from '../../types/ChangeFunctions';

import './CardStyle.css';

export const ContactInfoCard: FC<{
	customer: AboutMeData;
	handleTextChange: HandleTextChangeFunction;
	handleAddressChange: (address: ICASAddress) => void;
	handleFieldValidation: HandleFieldValidationFunction;
	invalidSubmit: boolean;
}> = ({
	customer,
	handleTextChange,
	handleAddressChange,
	handleFieldValidation,
	invalidSubmit,
}): JSX.Element => {
	useEffect(() => {
		if (!customer.landline && !customer.mobilePhone) {
			handleFieldValidation('phoneNumber', false);
		} else {
			handleFieldValidation('phoneNumber', true);
		}
	}, [customer.landline, customer.mobilePhone, handleFieldValidation]);

	return (
		<Stack as="section" gap={3} className="card card-small left">
			<div className="card__heading cyan">
				<h2>Contact info</h2>
			</div>
			<div className="card__content contactinformation-card">
				<legend>Phone numbers</legend>
				<ValidationTextInput
					id="landline"
					value={customer.landline}
					labelText="Landline"
					handleChange={handleTextChange}
					size={33}
					type="tel"
					vertical
					required={!customer.mobilePhone?.length}
					invalidSubmit={invalidSubmit}
				/>
				<ValidationTextInput
					id="mobilePhone"
					value={customer.mobilePhone || ''}
					labelText="Mobile"
					handleChange={handleTextChange}
					size={33}
					type="tel"
					vertical
					required={!customer.landline?.length}
					invalidSubmit={invalidSubmit}
				/>
				<hr />
				{customer.address && (
					<AddressContainer
						address={customer.address}
						labelText="Home address"
						handleAddressChange={handleAddressChange}
						onFieldValidation={handleFieldValidation}
						urlToSearch={`/lookup/address/?addressString=`}
						invalidSubmit={invalidSubmit}
						required
					/>
				)}
			</div>
		</Stack>
	);
};
