import React, { FC } from 'react';
import { Toggle } from 'icas.core.reactcomponents';

import { PreferencesData } from '../../types/PreferencesData';

import './CardStyle.css';

type CommunicationsCardProps = {
	data: PreferencesData;
	handleChange: (id: string, checked: boolean) => void;
};

export type ToggleSwitchInfo = {
	id: string;
	labelText: string;
	isChecked: boolean;
	description: string;
};

export const CommunicationsCard: FC<CommunicationsCardProps> = ({
	data,
	handleChange,
}): JSX.Element => {
	const {
		icasFoundationAndScabaUpdates,
		memberBenefits,
		doNotBulkEmail,
		weeklyUpdates,
		eventsUpdates,
		caMagazineUpdates,
		caJobsUpdates,
	} = data;

	/* do not bulk email info*/

	const doNotBulkEmailInfo: ToggleSwitchInfo = {
		/* id name used to dynamically update state object do not change without changing elsewhere */
		id: 'doNotBulkEmail',
		labelText: 'Unsubscribe from All Marketing',
		isChecked: doNotBulkEmail,
		description:
			'When you unsubscribe from all marketing emails then all options below will become read only.',
	};

	/* updates from ICAS charities info*/

	const icasFoundationAndScabaUpdatesToggleInfo: ToggleSwitchInfo = {
		/* id name used to dynamically update state object do not change without changing elsewhere */
		id: 'icasFoundationAndScabaUpdates',
		labelText: 'Receive charity updates',
		isChecked: icasFoundationAndScabaUpdates,
		description:
			'Get emails from ICAS on our charity partnerships, including the ICAS Foundation and SCABA.',
	};

	/* member rewards info */

	const memberToggleInfo: ToggleSwitchInfo = {
		/* id name used to dynamically update state object do not change without changing elsewhere */
		id: 'memberBenefits',
		labelText: 'Receive member rewards',
		isChecked: memberBenefits,
		description:
			'Get the monthly round up of top-brand discounts and value-added services, specifically chosen for you.',
	};

	/* weekly updates info*/

	const weeklyUpdatesToggleInfo: ToggleSwitchInfo = {
		/* id name used to dynamically update state object do not change without changing elsewhere */
		id: 'weeklyUpdates',
		labelText: 'Receive weekly updates',
		isChecked: weeklyUpdates ?? false,
		description:
			'Get CA Weekly every Tuesday, for industry, business and ICAS news, technical updates, CPD courses and events.',
	};

	/*events updates */

	const eventsUpdatesToggleInfo: ToggleSwitchInfo = {
		/*id name used to dynamically update state object do not change without changing elsewhere */
		id: 'eventsUpdates',
		labelText: 'Receive event updates',
		isChecked: eventsUpdates ?? false,
		description:
			'Get invites to in-person and online ICAS events, for networking, insights, technical updates and free CPD.',
	};

	/*ca magazine updates */

	const caMagazineUpdatesToggleInfo: ToggleSwitchInfo = {
		/*id name used to dynamically update state object do not change without changing elsewhere */
		id: 'caMagazineUpdates',
		labelText: 'Receive CA mag updates',
		isChecked: caMagazineUpdates ?? false,
		description:
			'Be first to receive the monthly magazine for CAs with news and features on business, accounting and technology.',
	};

	/*ca jobs updates */

	const caJobsUpdatesToggleInfo: ToggleSwitchInfo = {
		/*id name used to dynamically update state object do not change without changing elsewhere */
		id: 'caJobsUpdates',
		labelText: 'Receive CA jobs updates',
		isChecked: caJobsUpdates ?? false,
		description:
			'Be first to the latest vacancies in chartered accountancy, business and finance.',
	};

	return (
		<div className="card">
			<div className="card__heading orange">
				<h2>Email communications</h2>
			</div>
			<div
				className="card__content communications-card"
				style={{ marginBottom: '1.4em' }}
			>
				<Toggle
					onChange={handleChange}
					isChecked={doNotBulkEmailInfo.isChecked}
					labelText={doNotBulkEmailInfo.labelText}
					id={doNotBulkEmailInfo.id}
					style={{ backgroundColor: '#FEF3E9', padding: '16px', margin: '0' }}
				>
					<Toggle.Description description={doNotBulkEmailInfo.description} />
					<Toggle.Switch />
				</Toggle>
			</div>
			<div
				className="card__content communications-card"
				style={{ marginBottom: '1.4em' }}
			>
				<Toggle
					onChange={handleChange}
					isChecked={weeklyUpdatesToggleInfo.isChecked}
					labelText="Weekly Updates"
					id={weeklyUpdatesToggleInfo.id}
				>
					<Toggle.Description
						description={weeklyUpdatesToggleInfo.description}
					/>
					<Toggle.Switch disabled={doNotBulkEmailInfo.isChecked} />
				</Toggle>
				<Toggle
					onChange={handleChange}
					isChecked={eventsUpdatesToggleInfo.isChecked}
					labelText="ICAS Events and Webinars"
					id={eventsUpdatesToggleInfo.id}
				>
					<Toggle.Description
						description={eventsUpdatesToggleInfo.description}
					/>
					<Toggle.Switch disabled={doNotBulkEmailInfo.isChecked} />
				</Toggle>
				<Toggle
					onChange={handleChange}
					isChecked={caMagazineUpdatesToggleInfo.isChecked}
					labelText="CA Magazine"
					id={caMagazineUpdatesToggleInfo.id}
				>
					<Toggle.Description
						description={caMagazineUpdatesToggleInfo.description}
					/>
					<Toggle.Switch disabled={doNotBulkEmailInfo.isChecked} />
				</Toggle>
			</div>
			<div className="card__content communications-card">
				<Toggle
					onChange={handleChange}
					isChecked={caJobsUpdatesToggleInfo.isChecked}
					labelText="CA Jobs"
					id={caJobsUpdatesToggleInfo.id}
				>
					<Toggle.Description
						description={caJobsUpdatesToggleInfo.description}
					/>
					<Toggle.Switch disabled={doNotBulkEmailInfo.isChecked} />
				</Toggle>
				<Toggle
					onChange={handleChange}
					isChecked={icasFoundationAndScabaUpdatesToggleInfo.isChecked}
					labelText="Charity updates"
					id={icasFoundationAndScabaUpdatesToggleInfo.id}
				>
					<Toggle.Description
						description={icasFoundationAndScabaUpdatesToggleInfo.description}
					/>
					<Toggle.Switch disabled={doNotBulkEmailInfo.isChecked} />
				</Toggle>
				<Toggle
					onChange={handleChange}
					isChecked={memberToggleInfo.isChecked}
					labelText="Member rewards"
					id={memberToggleInfo.id}
				>
					<Toggle.Description description={memberToggleInfo.description} />
					<Toggle.Switch disabled={doNotBulkEmailInfo.isChecked} />
				</Toggle>
			</div>
			<hr />
			<div className="card__footer">
				<h3>Our Privacy Statement</h3>
				<p>
					The security of your personal information is extremely important to
					ICAS. Our statement details how we look after your important data.{' '}
					<a
						href="https://www.icas.com/privacy"
						target="_blank "
						rel="noopener noreferrer"
					>
						View privacy statement
					</a>
				</p>
			</div>
		</div>
	);
};
