import { useCallback, useContext, useEffect, useState } from 'react';
import { LoadingStatus } from 'icas.core.reactcomponents';

import { AppContext, MenuItem } from '../App';
import { noGAAMemberships } from '../components/Card/MembershipInfoCard';
import { PostDataFunction } from '../pages/MyWork/useWorkForm';
import { AboutMeData } from '../types/AboutMeData';
import { ICASAddress } from '../types/Address';
import {
	HandleSelectChangeFunction,
	HandleTextChangeFunction,
} from '../types/ChangeFunctions';
import { customErrorInfo } from '../types/CustomErrorInfo';
import { MyDiversityData } from '../types/MyDiversityData';
import { OptionSet } from '../types/OptionSet';
import { PreferencesData } from '../types/PreferencesData';
import {
	isNotEmptyObject,
	isNumberString,
	isValidString,
} from '../utils/validation';

import { useCurrentPath } from './useCurrentPath';
import { useInvalidFieldList } from './useInvalidFieldList';

const findOptionSet = (
	value: number,
	optionSetArray: OptionSet[]
): OptionSet | undefined =>
	optionSetArray.find(option => option.value === value);

const isPreferencesData = (
	data: Record<string, unknown>
): data is PreferencesData =>
	isNotEmptyObject(data) && 'showBusinessAddress' in data;

const isAboutMeData = (data: any): data is AboutMeData =>
	isNotEmptyObject(data) &&
	'title' in data &&
	'firstName' in data &&
	'email' in data;

export type SaveInfo = {
	saveSent: boolean;
	saveState: LoadingStatus;
};

const noSave: SaveInfo = {
	saveSent: false,
	saveState: LoadingStatus.IsNotLoading,
};

export const useHandleFormLogic = (
	data: AboutMeData | PreferencesData | MyDiversityData,
	postData: PostDataFunction,
	postDataUrl: string,
	errorInfo?: customErrorInfo
) => {
	const [currentData, setCurrentData] = useState<
		AboutMeData | PreferencesData | MyDiversityData
	>({} as AboutMeData | PreferencesData);
	const [newData, setNewData] = useState<
		AboutMeData | PreferencesData | MyDiversityData
	>({} as AboutMeData | PreferencesData);
	const [isDirty, setIsDirty] = useState(false);
	const [formAction, setFormAction] = useState('');
	const [saveInfo, setSaveInfo] = useState<SaveInfo>(noSave);
	const [invalidSubmission, setInvalidSubmission] = useState(false);
	const { invalidFields, handleFieldValidation } = useInvalidFieldList();

	useEffect(() => {
		setCurrentData(data);
		setNewData(data);
	}, [data]);

	useEffect(() => {
		if (invalidSubmission) {
			const clearSubmission = setTimeout(
				() => setInvalidSubmission(false),
				3000
			);

			return () => {
				clearTimeout(clearSubmission);
			};
		}
	}, [invalidSubmission]);

	useEffect(() => {
		if (saveInfo.saveState === LoadingStatus.EndedWithError) {
			const clearSubmission = setTimeout(
				() =>
					setSaveInfo({
						saveSent: false,
						saveState: LoadingStatus.IsNotLoading,
					}),
				3000
			);

			return () => {
				clearTimeout(clearSubmission);
			};
		}
	}, [saveInfo.saveState]);

	const handleFormSubmit = useCallback(() => {
		const submitForm = async () => {
			setSaveInfo({
				saveSent: true,
				saveState: LoadingStatus.IsLoading,
			});
			const { success: saveStatus } = await postData(newData, postDataUrl);
			if (saveStatus === LoadingStatus.LoadedSuccessfully) {
				const data = { ...newData };
				setCurrentData(data);
				setIsDirty(false);
			}
			setSaveInfo({
				saveSent: true,
				saveState: saveStatus,
			});
		};
		if (!invalidFields.length) {
			if (isDirty) {
				submitForm();
			}
		} else {
			setInvalidSubmission(true);
		}
	}, [invalidFields.length, isDirty, newData, postData, postDataUrl]);

	const handleFormReset = useCallback(() => {
		const oldData = { ...currentData };
		setNewData(oldData);
		setIsDirty(false);
		setSaveInfo(noSave);
	}, [currentData]);

	useEffect(() => {
		if (formAction) {
			setInvalidSubmission(false);
			if (formAction === 'Submit') {
				handleFormSubmit();
			}
			if (formAction === 'Reset') {
				handleFormReset();
			}
		}
		return () => setFormAction('');
	}, [formAction, handleFormReset, handleFormSubmit]);

	const handleFormDirty = () => {
		setIsDirty(true);
		setSaveInfo(noSave);
	};

	const handleSelectChange: HandleSelectChangeFunction = (
		fieldId: string,
		value: string | number
	) => {
		handleFormDirty();
		const id = fieldId as keyof typeof newData;
		setNewData(prevState => {
			const currentValue = prevState[id];
			const valueNeedsParsed =
				isValidString(value) &&
				(typeof currentValue === 'number' || isNumberString(value));
			if (valueNeedsParsed) {
				return { ...prevState, [id]: parseInt(value) };
			}
			return { ...prevState, [id]: value };
		});
	};

	const handleTextChange: HandleTextChangeFunction = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		handleFormDirty();
		const id = e.currentTarget.id as keyof typeof newData;
		const newValue = e.currentTarget.value;
		if (newValue) {
			return setNewData(prevState => ({ ...prevState, [id]: newValue }));
		}
		setNewData(prevState => ({ ...prevState, [id]: null }));
	};

	const handleGAAMembershipChange: HandleSelectChangeFunction = (
		_: string,
		value: string | number
	) => {
		if (isAboutMeData(newData)) {
			setIsDirty(true);
			const originalInstituteMembership =
				value === noGAAMemberships.value &&
				newData.gaaMembership?.isOriginalInstitute;

			const noGaaMembership =
				value === noGAAMemberships.value &&
				!newData.gaaMembership?.isOriginalInstitute;

			// Members cannot remove a GAA membership if it is their original institute
			if (originalInstituteMembership) {
				return;
			}
			if (noGaaMembership) {
				return setNewData(prevState => ({
					...prevState,
					gaaMembership: {
						organisationId: null,
						isOriginalInstitute: false,
					},
				}));
			}
			return setNewData(prevState => ({
				...prevState,
				gaaMembership: {
					organisationId: value.toString(),
					isOriginalInstitute: false,
				},
			}));
		}
	};

	const handleCheckboxChange = (id: string, checked: boolean) => {
		handleFormDirty();
		const stateName = id as keyof typeof newData;
		setNewData({
			...newData,
			[stateName]: checked,
		});
	};

	const handleRadioButtonChange = (name: string, value: string | number) => {
		handleFormDirty();
		const stateName = name as keyof typeof newData;
		if (typeof value == 'string') {
			return setNewData({
				...newData,
				[stateName]: parseInt(value),
			});
		}
		setNewData({
			...newData,
			[stateName]: value,
		});
	};

	const handleMultiSelectChange = (
		id: string,
		checked: boolean,
		optionSetArray: OptionSet[]
	) => {
		handleFormDirty();
		const value = parseInt(id);
		if (checked) {
			const optionSet = findOptionSet(value, optionSetArray);
			if (optionSet) {
				addMultiSelectToState(value, optionSet);
			}
		} else {
			removeMultiSelectFromState(value);
		}
	};

	const addMultiSelectToState = (value: number, optionSet: OptionSet) => {
		if (isPreferencesData(newData)) {
			const data: PreferencesData = { ...newData };
			data.interests = [
				...data.interests,
				{ Key: value, Value: optionSet?.name },
			];
			setNewData(data);
		}
	};

	const removeMultiSelectFromState = (value: number) => {
		if (isPreferencesData(newData)) {
			const currentInterests = [...newData.interests];
			const interestRemoved = currentInterests.filter(
				interest => interest.Key !== value
			);

			setNewData(prevState => ({
				...prevState,
				interests: interestRemoved,
			}));
		}
	};

	const handleAddressChange = (address: ICASAddress) => {
		handleFormDirty();
		setNewData(prevState => ({ ...prevState, address: address }));
	};

	return {
		newData,
		isDirty,
		invalidSubmission,
		handleAddressChange,
		handleSelectChange,
		handleCheckboxChange,
		handleRadioButtonChange,
		handleTextChange,
		handleFieldValidation,
		handleMultiSelectChange,
		handleGAAMembershipChange,
		setFormAction,
		saveInfo,
	};
};
